<template>
    <div class="backcolor print-area">
      <div
        class="darkcolor pt-6 pt-sm-8 element-to-hide"
        :style="$vuetify.breakpoint.smAndDown ? '' : 'height: 35vh'"
      >
        <div
          class="white--text d-flex justify-space-between mx-auto"
          :class="$vuetify.breakpoint.xsOnly ? 'flex-column' : ''"
          style="width: 90vw; height: 60%"
        >
          <div class="d-flex flex-column">
            <span
              style="letter-spacing: 1.2px"
              class="font-weight-bold"
              :style="
                $vuetify.breakpoint.mdAndDown
                  ? 'font-size: 1.5rem'
                  : 'font-size: 2.5rem'
              "
            >
              {{ $t('student.student_detalhes.detalhes_progresso') }}: {{ firstName }}
            </span>
            <span
              class="mt-3"
              :style="
                $vuetify.breakpoint.mdAndDown
                  ? 'font-size: 0.8rem'
                  : 'font-size: 0.9rem'
              "
            >
              {{ $t('student.student_detalhes.detalhes_acompanhe') }}
            </span>
          </div>
          <div class="d-flex flex-column align-end justify-space-between py-4">
            <span
              :style="
                $vuetify.breakpoint.mdAndDown
                  ? 'font-size: 0.8rem'
                  : 'font-size: 0.9rem'
              "
              class="mt-2 mt-md-0 font-weight-regular"
            >
              {{ date }}
            </span>
            <!-- <v-btn
              class="roundex-lg mt-2 mt-md-0"
              color="pink"
              outlined
              @click="printLandscape()"
            >
              Imprimir <v-icon right>mdi-printer</v-icon>
            </v-btn> -->
          </div>
        </div>
      </div>
      <v-row
        class="mt-4 mx-auto"
        :style="printing ? 'width: 100vw' : 'width: 95vw'"
        align="center"
        :class="printing ? 'mt-md-4' : 'mt-md-n16'"
      >
        <v-col cols="6" :md="chartDataExist ? 3 : 4">
          <v-card
            class="d-flex flex-column pa-4 rounded-lg justify-center"
            height="180px"
          >
            <span>
              <v-icon class="pa-2 rounded-lg pink-bg" color="darkpink">
                mdi-account
              </v-icon>
            </span>
  
            <span
              class="mt-6 font-weight-bold"
              :style="
                $vuetify.breakpoint.mdAndDown
                  ? 'font-size: 1.2rem'
                  : 'font-size: 1.5rem'
              "
            >
              {{ firstName }} {{ lastName }}
            </span>
            <span class="font-weight-medium">
              {{ (studentInfo) ? studentInfo.student.user : "" }}
            </span>
          </v-card>
        </v-col>
        <v-col cols="6" :md="chartDataExist ? 3 : 4">
          <v-card
            class="d-flex flex-column pa-4 rounded-lg justify-center"
            height="180px"
          >
            <span>
              <v-icon class="pa-2 rounded-lg cyan-bg" color="darkcyan">
                mdi-school
              </v-icon>
            </span>
  
            <span
              class="mt-6 font-weight-bold"
              :style="
                $vuetify.breakpoint.mdAndDown
                  ? 'font-size: 1.2rem'
                  : 'font-size: 1.5rem'
              "
            >
              {{ (studentInfo) ? studentInfo.student.school.nickname : "" }}
            </span>
            <span class="font-weight-medium"> {{ $t('student.student_detalhes.detalhes_institucao') }} </span>
          </v-card>
        </v-col>
        <v-col cols="6" :md="chartDataExist ? 3 : 4">
          <v-card
            class="d-flex flex-column pa-4 rounded-lg justify-center"
            height="180px"
          >
            <span>
              <v-icon class="pa-2 rounded-lg yellow-bg" color="darkyellow">
                mdi-script-text
              </v-icon>
            </span>
  
            <span
              class="mt-6 font-weight-bold"
              :style="
                $vuetify.breakpoint.mdAndDown
                  ? 'font-size: 1.2rem'
                  : 'font-size: 1.5rem'
              "
            >
              {{ (studentInfo) ? studentInfo.student.team.name : "" }}
            </span>
            <span class="font-weight-medium"> {{ $t('student.student_detalhes.detalhes_turma') }} </span>
          </v-card>
        </v-col>
        <v-col cols="6" md="3" v-if="chartDataExist">
          <v-card
            class="d-flex flex-column pa-4 rounded-lg justify-center"
            height="180px"
          >
            <span>
              <v-icon class="pa-2 rounded-lg blue-bg" color="darkblue">
                mdi-star
              </v-icon>
            </span>
  
            <span
              class="mt-6 font-weight-bold"
              :style="
                $vuetify.breakpoint.mdAndDown
                  ? 'font-size: 1.2rem'
                  : 'font-size: 1.5rem'
              "
            >
              {{ avarage }}
            </span>
            <span class="font-weight-medium"> {{ $t('student.student_detalhes.detalhes_media') }} </span>
          </v-card>
        </v-col>
      </v-row>
      <v-row
        class="mt-4 mx-auto d-flex"
        :style="printing ? 'width: 100vw' : 'width: 95vw'"
        align="center"
      >
        <v-col cols="12" v-if="chartDataExist">
          <v-card class="rounded-lg pa-1 pa-md-4">
            <v-card-title
              :style="$vuetify.breakpoint.xsOnly ? 'font-size: 1rem' : ''"
              class="font-weight-bold"
            >
              {{ $t('student_detalhes.detalhes_aula.aula_progresso') }}
              <v-tooltip right max-width="350px">
                <template v-slot:activator="{ on }">
                  <v-icon right v-on="on"> mdi-help-circle-outline </v-icon>
                </template>
                {{ $t('student_detalhes.detalhes_aula.aula_detalhes') }}
              </v-tooltip>
            </v-card-title>
            <v-card-text class="px-0 px-md-4">
              <GradeChart :chartData="chartData" ref="gradeChart" />
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="6" class="align-self-stretch">
          <v-card class="rounded-lg pa-1 pa-md-4" style="height: 100%">
            <v-card-title
              :style="$vuetify.breakpoint.xsOnly ? 'font-size: 1rem' : ''"
              class="font-weight-bold"
            >
              {{ $t('student_detalhes.detalhes_curso.curso_title') }}
              <v-tooltip right max-width="280px">
                <template v-slot:activator="{ on }">
                  <v-icon right v-on="on"> mdi-help-circle-outline </v-icon>
                </template>
                {{ $t('student_detalhes.detalhes_curso.curso_detalhes.detalhes1') }} <b> {{ $t('student_detalhes.detalhes_curso.curso_detalhes.detalhes2') }} </b>, <b> {{ $t('student_detalhes.detalhes_curso.curso_detalhes.detalhes3') }} </b> {{ $t('student_detalhes.detalhes_curso.curso_detalhes.detalhes4') }}
                <b> {{ $t('student_detalhes.detalhes_curso.curso_detalhes.detalhes5') }} </b> {{ $t('student_detalhes.detalhes_curso.curso_detalhes.detalhes6') }}
              </v-tooltip>
            </v-card-title>
            <v-card-text>
              <ClassChart
                :chartClass="chartClass"
                ref="classesChart"
                class="mx-auto"
              />
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="6">
          <v-card class="rounded-lg pa-1 pa-md-4">
            <v-card-title
              :style="$vuetify.breakpoint.xsOnly ? 'font-size: 1rem' : ''"
              class="font-weight-bold"
            >
              {{ $t('student_detalhes.detalhes_curso.curso_frequencia.frequencia1') }}
              <v-tooltip right max-width="350px">
                <template v-slot:activator="{ on }">
                  <v-icon right v-on="on"> mdi-help-circle-outline </v-icon>
                </template>
                {{ $t('student_detalhes.detalhes_curso.curso_frequencia.frequencia2') }}
                <div style="font-size: 0.7rem; color: #e0e0e0" class="mt-4">
                  {{ $t('student_detalhes.detalhes_curso.curso_frequencia.frequencia3') }}
                </div>
              </v-tooltip>
            </v-card-title>
            <v-card-text>
              <LoginChart
                :loginChart="loginChart"
                ref="chartLogin"
                class="mx-auto"
              />
            </v-card-text>
          </v-card>
        </v-col>
  
        <!--TIMELINE-->
        <v-col cols="12" v-if="classes.length > 0">
          <v-card class="rounded-lg pa-1 pa-md-4">
            <v-card-title class="font-weight-bold">
              {{ $t('student_detalhes.detalhes_curso.curso_aula') }}
            </v-card-title>
            <v-card-text class="px-0 px-md-4">
              <v-timeline>
                <v-timeline-item
                  v-for="aula in classes"
                  :key="aula.id"
                  color="teal"
                  :icon="aula.applied == 1 ? 'mdi-check' : 'mdi-lock'"
                  :icon-color="aula.applied == 1 ? 'green' : 'grey'"
                  class="timeline-item-custom"
                >
                  <template v-slot:opposite>
                    <v-avatar color="grey lighten-2" class="avatar-custom">
                      <v-img
                        max-height="180"
                        lazy-src="http://www.makereducacional.com.br/images/Curso Padrão PT.png"
                        :src="aula.tumbnail == null ? aula.thumbCurso : aula.tumbnail"
                        :aspect-ratio="9 / 16"
                      />
                    </v-avatar>
                  </template>
  
                  <div class="content-custom">
                    <v-btn
                      v-if="aula.applied == 1"
                      text
                      @click="showPopup(aula)"
                      class="my-custom-btn"
                    >
                      {{ aula.title }}
                    </v-btn>
                    <span v-else>{{ aula.title }}</span>
                  </div>
                </v-timeline-item>
              </v-timeline>
            </v-card-text>
          </v-card>
        </v-col>
  
      </v-row>
  
      <!--POPUP FOTOS-->
      <v-dialog v-model="dialog" persistent max-width="600px" v-if="classes.length > 0">
        <v-card>
          <v-card-title>
            {{ $t('student_detalhes.detalhes_curso.curso_foto') }} {{ classes[currentClassIndex].title }}
            <v-spacer></v-spacer>
            <v-btn icon @click="dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            {{ classes[currentClassIndex].description }}
          </v-card-text>
          <v-spacer></v-spacer>
          <v-card-text>
            <v-carousel v-if="classes[currentClassIndex].photos.length > 0">
              <v-carousel-item
                v-for="(foto, index) in classes[currentClassIndex].photos"
                :key="index"
              >
                <v-img :src="foto"></v-img>
              </v-carousel-item>
            </v-carousel>
            <div v-else>
              <v-alert type="info" dismissible>
                {{ $t('alerta.alerta_fotos') }}
              </v-alert>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
  
    </div>
  </template>
  
  <script>
  const axios = require("axios");
  const url = process.env.VUE_APP_API_URL;
  const token = "Baerer " + localStorage.getItem("accessToken");
  const authorization = { headers: { authorization: token } };
  
  import GradeChart from "../Student/Grades/gradeChart.vue";
  import ClassChart from "../Student/Grades/classesChart.vue";
  import LoginChart from "../Student/Grades/loginChart.vue";
  
  export default {
    components: { GradeChart, ClassChart, LoginChart },
    data() {
      return {
        avarage: 0,
        chartDataExist: true,
        chartData: { name: [], data: [] },
        loginChart: { name: [], data: [] },
        chartClass: [],
        studentInfo: null,
        courseId: null, 
        firstName: "",
        lastName: "",
        date: "",
        printing: false,
  
        classes: [],
        dialog: false,
        currentClassIndex: 0,
      };
    },
    created() {
      this.getStudentInfo();

      setTimeout(() => {
        this.getFirst();
        this.getTodayDate();
        this.getData(this.courseId);
        this.getLoginFrequence();
        this.getClasses(this.courseId);
      }, 500);
    },
  
    methods: {
      async getStudentInfo() {
        let studentId = this.$route.query.studentId;
        let profile = await axios.get(`${url}/studentProfileByID/${studentId}`, {
            headers: {
            authorization: token,
            },
        });
        if (profile.data == null) {
                this.firstAccess = true;
                profile.data = {
                avatar: "http://www.makereducacional.com.br/astronaut/avatars/Astronaut.svg",
                cover: "http://www.makereducacional.com.br/astronaut/covers/CoverPhoto_BluePattern.svg",
                student: { user: "Aluno Maker Educacional", team: { Course: { id: 2 } } },
            };
        }
        this.studentInfo = profile.data;
        this.courseId = this.studentInfo.student.team.Course.id;
        this.$router.replace({ query: { ...this.$route.query, studentId: undefined } });
      }, 
      getFirst() {
        const fullName = this.studentInfo.student.name;
        const splitedName = fullName.split(/\s+/);
        this.firstName = splitedName[0];
        this.lastName = splitedName[splitedName.length - 1];
      },
      getTodayDate() {
        const date = new Date();
  
        const options = {
          timeZone: "America/Sao_Paulo",
          weekday: "long",
          day: "2-digit",
          month: "long",
          year: "numeric",
        };
  
        const formatter = new Intl.DateTimeFormat("pt-BR", options);
        const formattedDate = formatter.format(date);
  
        const weekday = formattedDate.split(",")[0];
        const capitalizedWeekday =
          weekday.charAt(0).toUpperCase() + weekday.slice(1);
        const monthName = formattedDate.split(" de ")[1];
  
        const capitalizedMonthName =
          monthName.charAt(0).toUpperCase() + monthName.slice(1);
  
        const formattedDateWithWeekdayAndCapitalizedMonth = formattedDate
          .replace(weekday, capitalizedWeekday)
          .replace(monthName, capitalizedMonthName);
  
        this.date = formattedDateWithWeekdayAndCapitalizedMonth;
      },
      async getData(courseId) {
    let id = courseId;
    let response = await axios.get(
      `${url}/grades/recordsCard/${this.studentInfo.student.id}/${id}`,
      authorization
    );

    let pedant = 0;
    let progress = 0;
    let concluded = 0;

    // Inicializa o mapa de aulas vazio
    let aulasMap = {};

    for (let i = 0; i < response.data.length; i++) {
      const element = response.data[i];

      // Agora, vamos verificar se a aula tem exercício e tratar a lógica de agregação
      if (element.hasExercise === 1) {
        // Se a aula não existir no mapa ou se a nota atual for maior que a armazenada, atualize-a
        if (!aulasMap[element.title] || element.grade > aulasMap[element.title].grade) {
          aulasMap[element.title] = {
            grade: element.grade,
          };
        }
      }

      const key = `${element.hasExercise}-${element.exercise}-${element.applied}`;
      switch (key) {
        case "0-0-0":
        case "1-0-0":
          pedant += 1;
          break;
        case "1-0-1":
        case "1-1-0":
          progress += 1;
          break;
        case "0-0-1":
        case "1-1-1":
          concluded += 1;
          break;
        default:
          console.error("Invalid Stage");
          break;
      }
    }

    this.chartClass.push(concluded, progress, pedant);

    // Antes de atualizar os dados do gráfico, vamos limpar os dados antigos
    this.chartData.name = [];
    this.chartData.data = [];

    // Preenchendo os dados do gráfico a partir do mapa de aulas
    Object.keys(aulasMap).forEach(title => {
      this.chartData.name.push(title);
      this.chartData.data.push(aulasMap[title].grade);
    });

    // Verifica se há dados no gráfico
    this.chartDataExist = this.chartData.data.length > 0;

    // Recalculando a média com os dados atualizados
    let sum = this.chartData.data.reduce((acc, grade) => acc + grade, 0);
    let leng = this.chartData.data.length;
    this.avarage = leng > 0 ? (sum / leng).toFixed(1) : 0;

    // Atualizando os gráficos
    this.$refs.classesChart.updateClass();
    this.$refs.gradeChart.updateData();
},
      async getLoginFrequence() {
        let response = await axios.get(`${url}/access/recordCard/${this.studentInfo.student.id}`, authorization);
  
        for (let index = 0; index < response.data.result.length; index++) {
          const element = response.data.result[index];
  
          this.loginChart.name.push(element.loginDate);
          this.loginChart.data.push(element.qtd);
        }
        this.$refs.chartLogin.updateLogin();
      },
  
      printLandscape() {
        this.printing = true;
        setTimeout(() => {
          window.print();
        }, 500);
  
        setTimeout(() => {
          this.printing = false;
        }, 4000);
      },
  
  
      showPopup(aula) {
        this.currentClassIndex = this.classes.indexOf(aula);
        this.fetchLoadedPhotos();
        this.dialog = true;
      },
      async getClasses(courseId) {
        axios
          .get(`${url}/classesForStudent/${this.studentInfo.student.id}/${courseId}`, {
            headers: {
              authorization: token,
            },
          })
          .then((res) => {
            this.classes = res.data.map(classe => {
              return { ...classe, photos: [] };
            });
          })
          .catch((err) => {
            console.error(err);
          });
      },
      async fetchLoadedPhotos() {
        try {
          const response = await axios.get(
            `${url}/appliedClasses/loadPhotos/${this.classes[this.currentClassIndex].id}/${this.studentInfo.student.teamId}`,
            authorization
          );
          this.classes[this.currentClassIndex].photos = response.data.urls;
        } catch (error) {
          console.error(error);
        }
      },
    },
  };
  </script>
  
  <style lang="css" scoped>
  .pink-bg {
    background-color: #ff00ff2a;
  }
  .cyan-bg {
    background-color: #00ffff44;
  }
  .yellow-bg {
    background-color: #ffff0054;
  }
  .blue-bg {
    background-color: #0000ff1c;
  }
  
  .timeline-item-custom .v-timeline-item__body {
    display: flex;
    justify-content: center; /* Centraliza o conteúdo */
    width: 100%; /* Garante que o flex container ocupe toda a largura disponível */
    text-align: center !important;
  }
  .content-custom {
    flex: 0 1 auto; /* Permite que o conteúdo se ajuste, mas não expanda além do necessário */
  }
  .my-custom-btn {
    background-color: #EEEEEE; /* Cor de fundo do botão */
    color: #000000; /* Cor do texto */
    border: 1px solid #CCCCCC; /* Opcional: Adiciona uma borda */
    box-shadow: 0px 2px 4px rgba(0,0,0,0.1); /* Opcional: Adiciona uma sombra leve */
    transition: background-color 0.3s ease; /* Suaviza a transição do fundo */
  }
  .my-custom-btn:hover {
    background-color: #E0E0E0; /* Cor de fundo no hover */
  }
  
  @media print {
    @page {
      size: portrait;
    }
    .element-to-hide {
      display: none !important;
    }
  }
  </style>
  