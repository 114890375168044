<template>
  <div>
    <v-toolbar dense class="elevation-1">
      <v-icon color="purple" class="text-h4">mdi-calendar-star</v-icon>
      <v-toolbar-title
        :style="
          $vuetify.breakpoint.smAndUp
            ? 'letter-spacing: 0.5rem'
            : 'letter-spacing: 0.2rem'
        "
        class="ml-3 font-weight-bold"
        >{{ $t('events.events_title') }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-dialog
        v-model="eventCreat"
        persistent
        max-width="60%"
        :fullscreen="$vuetify.breakpoint.mobile"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn dark color="darkpink" rounded v-bind="attrs" v-on="on">
            <v-icon left> mdi-plus-thick </v-icon>
            {{ $t('botoes.botao_novo') }}
          </v-btn>
        </template>
        <v-card class="rounded-lg">
          <v-card-title class="darkblue white--text">{{ $t('botoes.botao_novoEvent') }}</v-card-title>
          <v-card-text class="mt-5">
            <v-form ref="eventForm" v-model="valid" lazy-validation>
              <v-row>
                <v-col cols="12" sm="8">
                  <v-text-field
                    color="blue"
                    v-model="event.title"
                    :rules="[rules.required]"
                    :label="$t('label.label_titulo')"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-menu
                    v-model="datePick"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        color="blue"
                        v-model="dateFormatted"
                        :label="$t('label.label_data')"
                        prepend-icon="mdi-calendar"
                        readonly
                        :rules="[rules.date]"
                        v-bind="attrs"
                        v-on="on"
                        @blur="date = parseDate(dateFormatted)"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      locale="pt-BR"
                      color="blue"
                      no-title
                      v-model="date"
                      @input="datePick = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    color="blue"
                    :label="$t('label.label_descricao')"
                    rows="8"
                    filled
                    :rules="[rules.required]"
                    v-model="event.content"
                  ></v-textarea>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-file-input
                    show-size
                    color="blue"
                    truncate-length="20"
                    :label="editMode ? $t('homework.homework_image') : $t('label.label_imagem')"
                    accept="image/*"
                    v-model="thumb"
                    :rules="editMode ? [rules.none] : [rules.image]"
                  ></v-file-input>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    color="blue"
                    :label="$t('label.label_descricao')"
                    single-line
                    prepend-icon="mdi-link"
                    v-model="event.link"
                  ></v-text-field
                ></v-col>
                <v-col cols="12" sm="6">
                  <v-select
                    color="blue"
                    item-color="blue"
                    :items="audienceList"
                    v-model="event.audience"
                    :rules="[rules.required]"
                    @change="getAudienceSchools()"
                    :label="$t('label.label_public')"
                    required
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-autocomplete
                    v-model="event.teamId"
                    v-if="event.audience == 'students teams'"
                    :items="teams"
                    item-text="name"
                    item-value="id"
                    color="blue"
                    item-color="blue"
                    :label="$t('admin.admin_menu.menu13')"
                    multiple
                  >
                  </v-autocomplete>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <div class="mx-2 mx-sm-4 text-caption text--secondary">
               *{{ $t('regras.regra_obrigatorio') }}
            </div>
            <v-spacer></v-spacer>
            <v-btn color="darkpink" rounded text @click="cancelBtn()">
              {{ $t('botoes.botao_cancelar') }}
            </v-btn>
            <v-btn color="darkpink" rounded text @click="saveBtn()">
              {{ $t('botoes.botao_salva') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-toolbar>

    <v-row class="pa-6">
      <v-col v-for="evnt in eventsArray" :key="evnt.id" cols="12" md="6" lg="4">
        <v-card elevation="4">
          <v-card-title
            class="
              darkblue
              white--text
              d-flex
              justify-space-between
              flex-nowrap flex-row
            "
          >
            <div>
              <p class="pa-0 ma-0 text-caption">
                <span
                  class="
                    text-subtitle-1
                    font-weight-medium
                    text-uppercase
                    letter-spacing:
                    0.09rem
                  "
                  style="line-height: 0"
                  >{{ evnt.title }}</span
                >
                <br />
                {{ evnt.date }}
              </p>
            </div>
            <div class="d-flex flex-row" v-if="evnt.audience != 'all'">
              <!-- TABLE FIELDS -->
              <v-btn text small fab @click="editEvent(evnt)">
                <v-icon color="white"> mdi-pencil </v-icon></v-btn
              >

              <v-dialog max-width="500px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn text small fab v-bind="attrs" v-on="on">
                    <v-icon color="error">mdi-delete</v-icon>
                  </v-btn>
                </template>

                <v-card
                  class="rounded-lg py-3 error--text"
                  style="border-left: 12px inset red"
                >
                  <v-card-title>
                    <v-icon left large color="error">mdi-alert-circle</v-icon>
                    {{ $t('alerta.alerta_title') }}
                  </v-card-title>
                  <v-card-text class="black--text">
                    {{ $t('alerta.alerta_excluir.excluir4') }}
                  </v-card-text>
                  <v-card-actions class="d-flex justify-end pr-4">
                    <v-btn
                      color="error"
                      dark
                      rounded
                      class="px-3"
                      @click="deleteEvent(evnt)"
                    >
                      {{ $t('botoes.botao_excluir') }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
          </v-card-title>

          <v-card-text class="pa-0">
            <v-img
              :src="evnt.thumbnail"
              :height="$vuetify.breakpoint.xsOnly ? '150px' : '250px'"
              cointain
              loading
            ></v-img>
          </v-card-text>
          <v-card-actions class="px-4 px-md-6">
            <v-btn color="darkpink" rounded text class="px-4">
              <v-icon left> mdi-account-group </v-icon>
             {{ $t('botoes.botao_publico') }}
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn rounded color="darkpink" text class="px-4">
              <v-icon left> mdi-link-variant </v-icon>
              {{ $t('botoes.botao_inscricao') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col v-if="eventsArray.length === 0" cols="12" sm="10" class="mx-auto">
        <v-img
          src="../../assets/HoldingMoon.svg"
          max-width="30vw"
          class="mx-auto"
        />
        <div
          class="text-center text-h6 text-sm-h4 font-weight-bold mt-2"
          style="color: #491670"
        >
          <span
            >{{ $t('events.events_no') }}</span
          >
        </div>
      </v-col>
    </v-row>
    <v-snackbar
      v-model="errorSnackbar"
      timeout="2500"
      elevation="3"
      class="mx-auto"
      bottom
      color="error"
      outlined
      text
    >
      <v-icon
        :large="$vuetify.breakpoint.smAndUp ? true : false"
        color="error"
        left
        >mdi-close-circle
      </v-icon>
      <span class="black--text text-subtitle-1">
        {{ $t('alerta.alerta_erro') }}
      </span>
      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="errorSnackbar = null"
          rounded
          color="error"
          class="font-weight-black"
        >
        {{ $t('botoes.botao_ok') }}
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar
      v-model="successSnackbar"
      timeout="2500"
      elevation="3"
      class="mx-auto"
      bottom
      color="success"
      outlined
      text
    >
      <v-icon
        :large="$vuetify.breakpoint.smAndUp ? true : false"
        color="success"
        left
        >mdi-check-circle
      </v-icon>
      <span class="black--text text-subtitle-1">
        {{ $t('alerta.alerta_sucesso') }}
      </span>
      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="successSnackbar = false"
          rounded
          color="success"
          class="font-weight-black"
        >
         {{ $t('botoes.botao_ok') }}
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
const axios = require("axios");
const url = process.env.VUE_APP_API_URL;
const token = "Baerer " + localStorage.getItem("accessToken");
const authorization = { headers: { authorization: token } };
export default {
  data() {
    return {
      errorSnackbar: false,
      successSnackbar: false,
      date: null,
      dateFormatted: null,
      valid: true,
      eventCreat: false,
      editMode: false,
      datePick: false,
      thumb: null,
      rules: {
        email: (v) => !!(v || "").match(/@/) || this.$t('regras.regra_email'),
        length: (len) => (v) =>
          (v || "").length >= len ||
          `${this.$t('regras.regra_senhaMini.senha1')} ${len} ${this.$t('regras.regra_senhaMini.senha2')}`,
        required: (v) => !!v || this.$t('regras.regra_obrigatorio'),
        option: (v) => !!v || this.$t('regras.regra_opcao'),
        image: (v) => !!v || this.$t('regras.regra_image'),
        question: (v) => !!v || this.$t('regras.regra_question'),
        date: (v) => !!v || this.$t('regras.regra_data'),
        none: true,
      },
      audienceList: [
        { text: this.$t('events.events_headers.headers4'), value: "teams schools" },
        { text: this.$t('events.events_headers.headers5'), value: "students teams" },
      ],
      event: { audience: "teams schools" },
      teams: [],
      eventsArray: [],
    };
  },
  watch: {
    date() {
      this.dateFormatted = this.formatDate(this.date);
    },
  },
  mounted() {
    this.getEvents();
  },
  methods: { 
    async getAudienceSchools() {
      if (this.event.audience == "students teams") {
        console.log("entrei");
        this.event.schoolId = null;
        let res = await axios.get(`${url}/teamsForTeacher`, authorization);
        this.teams = res.data;
      }
    },
    getEvents() {
      axios
        .get(`${url}/eventsForTeacher`, {
          headers: {
            authorization: token,
          },
        })
        .then((res) => {
          this.eventsArray = res.data;

          this.eventsArray.forEach((element, index) => {
            this.eventsArray[index].date = this.showDate(element.date);
          });
        })
        .catch((err) => {
          console.error(err);
        });
    },
    async getAudienceTeams() {
      if (this.event.audience == "students teams") {
        this.event.teamId = null;
        let res = await axios.get(
          `${url}/teamsBySchool?school=${this.event.schoolId}`,
          authorization
        );
        this.teams = res.data;
      }
    },
    showDate(dateDB) {
      if (!dateDB) return null;
      const [date] = dateDB.split("T");
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    async registerThumb() {
      let formData = new FormData();
      formData.append("file", this.thumb, this.thumb.name);
      await axios
        .post(`${url}/events/files`, formData, {
          headers: {
            authorization: token,
          },
        })
        .then((response) => {
          let link = response.data.url;
          this.event.thumbnail = link;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    async saveBtn() {
      let validation = this.$refs.eventForm.validate();

      if (validation) {
        if (!this.editMode) {
          try {
            this.event.date = this.date;
            await this.registerThumb();
            await this.logicRegisterEvent();

            setTimeout(() => {
              this.successSnackbar = true;
            }, 1000);
          } catch (error) {
            this.errorSnackbar = true;
          }
        } else {
          try {
            this.event.date = this.parseDate(this.dateFormatted);
            if (this.thumb) {
              await this.registerThumb();
            }
            await this.updateEvent();
          } catch (error) {
            this.errorSnackbar = true;
          }
        }
        this.getEvents();
        this.eventCreat = false;
        this.editMode = false;
        this.$refs.eventForm.reset();
        this.event = { audience: "teams schools" };
      }
    },
    async registerEvent() {
      try {
        let res = await axios.post(`${url}/events`, this.event, authorization);
        this.event.eventId = res.data.success.id;
      } catch (error) {
        console.error(error);
      }
    },
    async logicRegisterEvent() {
      await this.registerEvent();
      console.log(this.event.audience);
      if (this.event.audience == "teams schools") {
        await this.registerEvViewers();
      }
      if (this.event.audience == "students teams") {
        let idsTeams = this.event.teamId;
        for (const idteam of idsTeams) {
          this.event.teamId = idteam;
          await this.registerEvViewers();
        }
      }
    },
    async registerEvViewers() {
      await axios
        .post(`${url}/eventViewersForTeacher`, this.event, authorization)
        .catch((error) => {
          console.error(error);
        });
    },
    cancelBtn() {
      this.$refs.eventForm.reset();
      this.eventCreat = false;
      this.editMode = false;
      this.event.audience = "all";
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    deleteEvent(register) {
      let eventId = register.id;
      this.deleteDialog = false;
      axios
        .delete(`${url}/events/${eventId}`, {
          headers: {
            authorization: token,
          },
        })
        .then(() => {
          this.getEvents();
          this.successSnackbar = true;
        })
        .catch((error) => {
          console.error(error);

          this.errorSnackbar = true;
        });
    },
    editEvent(register) {
      this.event = Object.assign({}, register);
      this.dateFormatted = this.event.date;
      this.eventCreat = true;
      this.editMode = true;
    },
    async updateEvent() {
      await axios
        .put(`${url}/events/${this.event.id}`, this.event, {
          headers: {
            authorization: token,
          },
        })
        .then(() => {
          setTimeout(() => {
            this.successSnackbar = true;
          }, 1000);
          this.getEvents();
        })
        .catch((error) => {
          console.error(error);
          this.errorSnackbar = true;
        });
    },
  },
};
</script>

<style scoped>
.truncate {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>
