<template>
  <v-app id="inspire">
    <v-app-bar src="../assets/Top_Bar-03.svg" app dark>
      <!-- <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon> -->
      <v-btn @click="drawer = !drawer" style="background: #e245a6;position: absolute;height: 50%;width: 6%;text-align: center;">MENU</v-btn>
      <v-spacer />
      <v-toolbar-title class="lign-center text-center">{{  $t('hometeacher.hometeacher_title')  }}<br>{{page}}</v-toolbar-title>
      <v-spacer />
      <v-btn icon disabled> </v-btn>
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" fixed temporary>
      <v-sheet>
        <v-img
          class="pa-4"
          src="http://www.makereducacional.com.br/astronaut/covers/CoverPhoto_BluePattern.svg"
          max-height="150px"
          position="right center"
        >
          <v-avatar size="80">
            <v-img
              class="rounded-circle"
              :src="
                teacherInfo.avatar === null
                  ? '../assets/DefaultAvatar.svg'
                  : teacherInfo.avatar
              "
              aspect-ratio="1"
            ></v-img>
          </v-avatar>
          <div class="pt-2 font-weight-medium white--text">
            {{ teacherInfo.name }}
          </div>
        </v-img>
      </v-sheet>

      <v-divider></v-divider>

      <v-list>
        <v-list-item
          @click="goSoftware()"
          >
            <v-list-item-icon>
              <v-icon>mdi-download</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ $t('home.home_titlesoftware') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        <v-list-item
          v-for="(item, i) in menu"
          :key="i"
          @click="
            drawer = false;
            openRouter(item.router)
          "
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              {{ item.title }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <div>
          <v-list-item @click="profileDialog = true">
            <v-list-item-icon>
              <v-icon>mdi-account</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title> {{  $t('botoes.botao_perfil')  }} </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <div class="logoutStyle">
          <v-list-item
            @click="
              logoutDialog = true;
              drawer = false;
            "
          >
            <v-list-item-icon>
              <v-icon>mdi-exit-to-app</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title> {{  $t('botoes.botao_sair')  }} </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>
        </div>
        
      </v-list>
    </v-navigation-drawer>
    <v-dialog v-model="logoutDialog" persistent max-width="320">
      <v-card elevation="15" class="rounded-xl py-4 px-2">
        <v-img src="../assets/HelloCircle2.svg" width="85px" class="mx-auto" />
        <v-card-title style="font-size: 1.1rem" class="mt-n3">
          {{  $t('alerta.alerta_sair.sair1')  }}
        </v-card-title>
        <v-card-text style="font-size: 0.9rem" class="mt-n2">
        {{  $t('alerta.alerta_sair.sair2')  }}
        </v-card-text>
        <v-card-actions class="mt-n2">
          <v-row no-gutters justify="center">
            <v-col cols="12" class="pb-3">
              <v-btn
                rounded
                color="darkblue"
                large
                dark
                block
                @click="logout()"
              >
                {{  $t('botoes.botao_sair')  }}
              </v-btn>
            </v-col>
            <v-col cols="12">
              <v-btn
                outlined
                rounded
                large
                color="darkblue"
                dark
                block
                @click="logoutDialog = false"
              >
                {{  $t('botoes.botao_cancelar')  }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="profileDialog"
      :max-width="widthDialog"
      :persistent="editMode"
    >
      <v-card class="rounded-lg pa-4">
        <v-card-title>
          <v-row no-gutters>
            <v-col cols="12" class="text-center">
              <v-img
                style="border: 3px solid #1670e8"
                :src="
                  teacherInfo.avatar === null
                    ? '../assets/DefaultAvatar.svg'
                    : teacherInfo.avatar
                "
                width="120px"
                :aspect-ratio="1"
                class="d-flex align-center text-center mx-auto rounded-circle"
              >
                <v-btn
                  fab
                  :x-large="$vuetify.breakpoint.smAndUp"
                  class="white--text elevation-24"
                  color="#00000090"
                  @click="handleFileImport()"
                  v-if="editMode"
                >
                  <v-icon>mdi-camera</v-icon>
                </v-btn>
              </v-img>
            </v-col>
            <input
              ref="uploader"
              class="d-none"
              type="file"
              accept="image/*"
              @change="onFileChanged"
            />
            <v-col cols="12" class="mt-4">
              <div class="text-center text-h6">{{ teacherInfo.name }}</div>
              <div class="text-center text-body-2">
                {{ teacherInfo.user }}
              </div>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-actions class="mt-8 mt-sm-4">
          <v-row v-if="!editMode">
            <v-col cols="12" class="text-center">
              <v-btn
                rounded
                color="darkpink"
                class="white--text"
                :width="$vuetify.breakpoint.xsOnly ? '200px' : '300px'"
                @click="editMode = true"
              >
                {{  $t('botoes.botao_meuperfil')  }}
              </v-btn>
            </v-col>
          </v-row>
          <v-form ref="editData">
            <v-row v-if="editMode" no-gutters>
              <v-col cols="12">
                <v-text-field
                  :label="$t('label.label_email')"
                  v-model="teacherInfo.email"
                  color="darkblue"
                  outlined
                  :rules="[rules.email]"
                  append-icon="mdi-at"
                >
                </v-text-field>
              </v-col>

              <v-col cols="12">
                <v-text-field
                  :label="$t('label.label_senha.senha')"
                  v-model="teacherInfo.user"
                  color="darkblue"
                  readonly
                  outlined
                  type="password"
                  @click="passDialog = true"
                  append-icon="mdi-lock-outline"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  :label="$t('label.label_celular.celular')"
                  v-model="teacherInfo.phone"
                  color="darkblue"
                  append-icon="mdi-cellphone"
                  v-mask="'(##) #########'"
                  outlined
                  :rules="[rules.required, rules.phoneLength(13)]"
                >
                </v-text-field>
              </v-col>

              <v-col cols="12" class="d-flex justify-end">
                <v-btn rounded color="darkpink" text @click="editMode = false">
                  {{  $t('botoes.botao_cancelar')  }}
                </v-btn>
                <v-btn
                  rounded
                  color="darkpink white--text ml-4"
                  @click="saveEdit()"
                >
                  {{  $t('botoes.botao_salva')  }}
                  <v-icon v-if="$vuetify.breakpoint.smAndUp" small right
                    >mdi-check</v-icon
                  >
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="passDialog"
      persistent
      :fullscreen="$vuetify.breakpoint.mobile"
      width="40%"
    >
      <v-card class="rounded-lg">
        <v-card-title class="darkblue white--text">
          {{  $t('label.label_senha.senha_redefinir')  }}
        </v-card-title>
        <v-card-text class="pt-6">
          <v-form ref="editPass">
            <v-row no-gutters>
              <v-col cols="12" class="px-2">
                <v-text-field
                  color="darkblue"
                  :label="$t('label.label_senha.senha_atual')"
                  outlined
                  :rules="[rules.required, rules.length(4), rules.verify]"
                  :type="showPassword1 ? 'text' : 'password'"
                  :append-icon="showPassword1 ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="showPassword1 = !showPassword1"
                  :placeholder="$t('label.label_senha.senha_redefinir')"
                  v-model="currentPassword"
                />
              </v-col>
              <v-col cols="12" sm="6" class="px-2">
                <v-text-field
                  color="darkblue"
                  :label="$t('label.label_senha.senha_nova')"
                  outlined
                  :rules="checkPasswordRules.concat(passwordCheckRule)"
                  :type="showPassword2 ? 'text' : 'password'"
                  :append-icon="showPassword2 ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="showPassword2 = !showPassword2"
                  :placeholder="$t('label.label_senha.senha_redefinir')"
                  v-model="newPassword"
                />
              </v-col>
              <v-col cols="12" sm="6" class="px-2">
                <v-text-field
                  color="darkblue"
                  :label="$t('label.label_senha.senha_confirmar')"
                  outlined
                  :rules="confirmPasswordRules.concat(passwordConfirmationRule)"
                  :type="showPassword3 ? 'text' : 'password'"
                  :append-icon="showPassword3 ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="showPassword3 = !showPassword3"
                  :placeholder="$t('label.label_senha.senha_redefinir')"
                  v-model="confirmPassword"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="px-4 pb-4">
          <v-spacer></v-spacer>
          <v-btn
            rounded
            color="darkpink"
            class="white--text px-4"
            text
            @click="passDialog = false"
          >
            {{  $t('botoes.botao_cancelar')  }}
          </v-btn>
          <v-btn
            rounded
            :loading="loading"
            color="darkpink"
            class="white--text px-4 ml-2"
            @click="editPasswordConfirm()"
          >
            {{  $t('botoes.botao_salva')  }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="firstAccess"
      persistent
      :fullscreen="$vuetify.breakpoint.mobile"
      width="40%"
    >
      <v-card class="rounded-lg">
        <v-card-title class="darkblue white--text">
          {{ $t('label.label_senha.senha_redefinir') }}
        </v-card-title>
        <v-card-text class="pt-6">
          <v-form ref="editPass">
            <v-row no-gutters>
              <v-col cols="12">
                <v-text-field
                  :label="$t('label.label_celular.celular_inserir')"
                  color="darkblue"
                  append-icon="mdi-cellphone"
                  v-mask="'(##) #########'"
                  outlined
                  v-model="phone"
                  :rules="[rules.required, rules.phoneLength(13)]"
                  class="px-2"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="6" class="px-2">
                <v-text-field
                  color="darkblue"
                  :label="$t('label.label_senha.senha_redefinir')"
                  outlined
                  :rules="[rules.required, rules.length(4)]"
                  :type="showPassword1 ? 'text' : 'password'"
                  :append-icon="showPassword1 ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="showPassword1 = !showPassword1"
                  :placeholder="$t('label.label_senha.senha_inserasenha')"
                  v-model="newPassword"
                />
              </v-col>
              <v-col cols="12" sm="6" class="px-2">
                <v-text-field
                  color="darkblue"
                  :label="$t('label.label_senha.senha_confirmar')"
                  outlined
                  :rules="confirmPasswordRules.concat(passwordConfirmationRule)"
                  :type="showPassword2 ? 'text' : 'password'"
                  :append-icon="showPassword2 ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="showPassword2 = !showPassword2"
                  :placeholder="$t('label.label_senha.senha_confirmarsua')"
                  v-model="confirmPassword"
                />
              </v-col>
              <v-row no-gutters>
                <v-checkbox v-model="enabled" color="darkblue">
                  <template v-slot:label>
                    <div>
                      {{ $t('hometeacher.hometeacher_termo.termo1') }}
                      <a
                        target="_blank"
                        href="https://next.vuetifyjs.com/en/components/checkboxes/"
                        class="darkblue--text"
                        @click.stop
                      >
                        {{ $t('hometeacher.hometeacher_termo.termo2') }}
                      </a>
                    </div>
                  </template>
                </v-checkbox>
              </v-row>
              <v-row no-gutters>
                <v-checkbox
                  class="mt-lg-n4"
                  v-model="emailNews"
                  color="darkblue"
                  :label="$t('label.label_email.email_aceita')"
                >
                </v-checkbox>
              </v-row>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="px-4 pb-4 mt-n6">
          <v-spacer></v-spacer>

          <v-btn
            rounded
            color="darkpink"
            class="white--text px-4 ml-2"
            @click="editPassword()"
            :disabled="!enabled"
          >
            {{ $t('botoes.botao_alterar') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="successSnackbar"
      timeout="2500"
      elevation="3"
      class="mx-auto"
      bottom
      color="success"
      outlined
      text
    >
      <v-icon
        :large="$vuetify.breakpoint.smAndUp ? true : false"
        color="success"
        left
        >mdi-check-circle
      </v-icon>
      <span class="black--text text-subtitle-1">
        {{ $t('alerta.alerta_sucesso') }}
      </span>
      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="successSnackbar = false"
          rounded
          color="success"
          class="font-weight-black"
        >
          {{ $t('botoes.botao_ok') }}
        </v-btn>
      </template>
    </v-snackbar>
    <v-main>
      <!-- Aqui -->
      <Kits v-if="page == $t('admin.admin_menu.menu1')" />
      <Classes v-if="page == $t('admin.admin_menu.menu3')" />
      <Courses v-if="page == $t('admin.admin_menu.menu2')" />
      <Homework v-if="page == $t('teams.team_atalho.atalho1')" />
      <Training v-if="page == $t('admin.admin_menu.menu5')" />
      <Files v-if="page == $t('homework.homework_tab.tab2')" />
      <Exercises v-if="page == $t('homework.homework_tab.tab6')" />
      <Events v-if="page == $t('teams.team_atalho.atalho5')" />
      <TeacherStudentDetails v-if="page == $t('hometeacher.hometeacher_progresso')" />
      <Schools v-if="page == $t('admin.admin_menu.menu9')" />
      <Teachers v-if="page == $t('admin.admin_menu.menu10')" />
      <StudentTeacher v-if="page == $t('admin.admin_menu.menu11')" />
      <TeamsTeacher v-if="page == $t('admin.admin_menu.menu13')" />
      <TeacherMenu v-if="page == 'Inicio'" />
      <Roles v-if="page == $t('teams.team_atalho.atalho4')" />
      <TeacherCert v-if="page == $t('teams.team_atalho.atalho6')" />
      <TeacherClass v-if="page == 'ClassInfo'" />
      <TeacherResume v-if="page == 'ClassResume'" />
    </v-main>
  </v-app>
</template>
<script>
import TeamsTeacher from "../components/Teacher/TeacherTeams.vue";
import StudentTeacher from "../components/TeacherFuture/TeacherStudent.vue";
import TeacherMenu from "../components/TeacherFuture/TeacherMenu.vue";
import Training from "../components/TeacherFuture/TeacherTraining.vue";
import Files from "../components/TeacherFuture/TeacherFiles.vue";
// import mercadopago from "../components/mercadopago.vue";
import Homework from "../components/TeacherFuture/TeacherHomework.vue";
import Events from "../components/TeacherFuture/TeacherEvents.vue";
import Roles from "../components/TeacherFuture/TeacherRoles.vue";
import TeacherStudentDetails from "../components/TeacherFuture/TeacherStudentDetails.vue";
import TeacherCert from "../components/TeacherFuture/TeacherCertif"
import TeacherClass from "../components/TeacherFuture/TeacherClass.vue"
import TeacherResume from "../components/TeacherFuture/TeacherClassResume.vue"


const axios = require("axios");
const url = process.env.VUE_APP_API_URL;
const token = "Baerer " + localStorage.getItem("accessToken");

var checking = true;
window.addEventListener('popstate', function() {
  location.reload();
  //this.routerReload();
});

export default {
  name: "Teacher",
  data: () => ({
    page: '',
    enabled: false,
    passDialog: false,
    emailNews: false,
    firstAccess: false,
    showPassword1: false,
    showPassword2: false,
    showPassword3: false,
    phone: null,
    teacherInfo: {},
    profileDialog: false,
    editMode: false,
    isSelecting: false,
    rules: {
      email: (v) =>
        !!(v || "").match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        ) || this.$t('regras.regra_email'),
      phoneLength: (len) => (v) =>
        (v || "").length >= len ||
        `${this.$t('regras.regra_telefone.telefone1')} ${len - 5} ${this.$t('regras.regra_telefone.telefone2')}`,
      length: (len) => (v) =>
        (v || "").length >= len ||
        `${this.$t('regras.regra_senhaMini.senha1')} ${len} ${this.$t('regras.regra_senhaMini.senha2')}`,
      maxLength: (len) => (v) =>
        (v || "").length <= len ||
        `${this.$t('regras.regra_senhaMax.senha1')} ${len} ${this.$t('regras.regra_senhaMax.senha2')}`,
      required: (v) => !!v || this.$t('regras.regra_obrigatorio'),
      option: (v) => !!v || this.$t('regras.regra_opcao'),
      image: (v) => !!v || this.$t('regras.regra_image'),
      question: (v) => !!v || this.$t('regras.regra_question'),
      date: (v) => !!v || this.$t('regras.regra_data'),
      user: (v) =>
        !!(v || "").match(/^[a-zA-Z0-9.]*$/) ||
        `${this.$t('regras.regra_user')}`,
      verify: () => checking || this.$t('regras.regra_senhaincorreta'),

      none: true,
    },
    trainingId: null,
    confirmPasswordRules: [(v) => !!v || this.$t('regras.regra_obrigatorio')],
    checkPasswordRules: [(v) => !!v || this.$t('regras.regra_obrigatorio')],
    loading: false,
    newPassword: "",
    confirmPassword: "",
    currentPassword: "",
    drawer: null,
    logoutDialog: false,
    checking: false,
    successSnackbar: false,
    menu: [],
  }),
  components: {
    // mercadopago,
    StudentTeacher,
    TeamsTeacher,
    Training,
    Files,
    Homework,
    Events,
    TeacherStudentDetails, 
    Roles,
    TeacherCert,
    TeacherClass,
    TeacherResume,
    TeacherMenu
  },
  computed: {
    passwordConfirmationRule() {
      return () =>
        this.newPassword === this.confirmPassword || this.$t('login.login_painel.coincidem');
    },
    passwordCheckRule() {
      return () =>
        this.newPassword !== this.currentPassword ||
        this.$t('login.login_painel.senhaigual');
    },
    widthDialog() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "90%";
        case "sm":
          return "90%";
        case "md":
          return "50%";
        case "lg":
          return "30%";
        case "xl":
          return "30%";
        default:
          return "30%";
      }
    },
  },


  mounted() {
    document.title = this.$t('hometeacher.hometeacher_title');
    this.menu = [
      {
        title: "Inicio",
        icon: "mdi-menu",
      },
      {
        title: this.$t('admin.admin_menu.menu13'),
        router: "turma",
        icon: "mdi-account-group",
      },
      {
        title: this.$t('teams.team_atalho.atalho1'),
        router: "class",
        icon: "mdi-bookshelf",
      },
      {
        title: this.$t('teams.team_atalho.atalho2'),
        router: "student",
        icon: "mdi-account-school",
      },
      {
        title: this.$t('admin.admin_menu.menu5'),
        router: "training",
        icon: "mdi-file-tree",
      },
      {
        title: this.$t('teams.team_atalho.atalho4'),
        router: "roles",
        icon: "mdi-account-hard-hat",
      },
      {
        title: this.$t('teams.team_atalho.atalho5'),
        router: "events",
        icon: "mdi-calendar-star",
      },
      {
        title: this.$t('teams.team_atalho.atalho6'),
        router: "certificate",
        icon: "mdi-certificate",
      },
    ]
    this.routerReload()
  
    //this.$router.push("/teacher" + params)

    console.log(this.$route.path)
  },
  created() {
    this.page = this.$route.query.studentId && parseInt(this.$route.query.studentId) > 0 ? this.$t('hometeacher.hometeacher_progresso') : this.$t('admin.admin_menu.menu13')
    this.getTeacher();
  },
  
  methods: {
    
    goSoftware(){
      window.open("https://makereducacional.com.br/software");
    },
    openRouter(router){
      console.log(router)
      if(router == "training"){
        this.$router.push("/teacherFuture/Training")
      }
      else if(router == "roles"){
        this.$router.push("/teacherFuture/Role")
      }
      else if(router == "class"){
        this.$router.push("/teacherFuture/Class")
      }
      else if(router == "events"){
        this.$router.push("/teacherFuture/Events")
      }
      else if(router == "student"){
        this.$router.push("/teacherFuture/Student")
      }
      else if(router == "certificate"){
        this.$router.push("/teacherFuture/Certificate")
      }
      else if(router == "turma"){
        this.$router.push("/teacherFuture/Turma")
      }
      else{
        this.$router.push("/teacherFuture")
      }
      this.routerReload()
    },

    routerReload(){
    if(this.$route.path == '/teacherFuture/Training'){
      this.page = this.$t('admin.admin_menu.menu5')
    }
    else if(this.$route.path == '/teacherFuture/Role'){
      this.page = this.$t('teams.team_atalho.atalho4')
    }
    else if(this.$route.path == '/teacherFuture/Class'){
      this.page = this.$t('teams.team_atalho.atalho1')
    }
    else if(this.$route.path == '/teacherFuture/Events'){
      this.page = this.$t('teams.team_atalho.atalho5')
    }
    else if(this.$route.path == '/teacherFuture/Student'){
      this.page = this.$t('teams.team_atalho.atalho2')
      localStorage.setItem("licensed", this.teacherInfo.school.licensed);
    }
    else if(this.$route.path == '/teacherFuture/Certificate'){
      this.page = this.$t('teams.team_atalho.atalho6')
    }
    else if(this.$route.path == '/teacherFuture/Training/Files'){
      this.page = this.$t('homework.homework_tab.tab2')
      this.trainingId = parseInt(localStorage.getItem("id"))
    }
    else if(this.$route.path == '/teacherFuture/ClassInfo'){
      this.page = 'ClassInfo'
    }
    else if(this.$route.path == '/teacherFuture/ClassResume'){
      this.page = 'ClassResume'
    }
    else if(this.$route.path == '/teacherFuture/Turma'){
      this.page = this.$t('admin.admin_menu.menu13')
    }
    else{
      this.page = 'Inicio'
    }
    },

    changeMenuLicensed() {
      if (this.teacherInfo.school.licensed) {
        this.menu.splice(0, 1);
        this.page = this.$t('teams.team_atalho.atalho2');
      }
    },
    logutDialogOpen() {
      this.profileDialog = false;
      this.logoutDialog = true;
    },
    async editPasswordConfirm() {
      let validation = this.$refs.editPass.validate();

      if (validation) {
        let data = {
          newPassword: this.confirmPassword,
          currentPassword: this.currentPassword,
        };

        let res = await axios.post(
          `${url}/teachers/alterConfirmedPassword`,
          data,
          {
            headers: {
              authorization: token,
            },
          }
        );

        let verify = res.data;

        if (verify.status == "success") {
          this.$refs.editPass.reset();
          this.passDialog = false;
          this.successSnackbar = true;
          checking = true;
        } else {
          this.loading = true;
          setTimeout(() => {
            this.loading = false;
            checking = false;
            this.$refs.editPass.validate();
            setTimeout(() => {
              checking = true;
              this.$refs.editPass.validate();
            }, 2500);
          }, 1000);
        }
      }
    },
    async saveEdit() {
      let validation = this.$refs.editData.validate();

      if (validation) {
        try {
          let data = {
            email: this.teacherInfo.email,
            phone: this.teacherInfo.phone,
          };
          let id = this.teacherInfo.id;
          await axios.put(`${url}/teachers/${id}`, data, {
            headers: {
              authorization: token,
            },
          });
          this.successSnackbar = true;
          this.editMode = false;
          this.passDialog = false;
          this.profileDialog = false;
        } catch (error) {
          console.error(error);
        }
      }
    },
    handleFileImport() {
      this.isSelecting = true;

      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        },
        { once: true }
      );

      this.$refs.uploader.click();
    },
    async onFileChanged(e) {
      let selected = e.target.files[0];
      let formData = new FormData();
      formData.append("file", selected, selected.name);
      let res = await axios.post(`${url}/lessonsExtras/files`, formData);
      let uri = res.data.url;
      let data = { avatar: uri };
      let id = this.teacherInfo.id;
      await axios.put(`${url}/teachers/${id}`, data, {
        headers: {
          authorization: token,
        },
      });
      setTimeout(() => {
        this.teacherInfo.avatar = uri;
      }, 500);
    },
    async getTeacher() {
      let res = await axios.get(`${url}/teachers/findOne`, {
        headers: {
          authorization: token,
        },
      });

      this.teacherInfo = res.data;
      this.changeMenuLicensed();
      if (
        this.teacherInfo.acceptedTerms == null ||
        this.teacherInfo.acceptedTerms == 0
      ) {
        this.firstAccess = true;
      }
    },
    async editPassword() {
      let validation = this.$refs.editPass.validate();

      if (validation) {
        try {
          let data = {
            phone: this.phone,
            password: this.confirmPassword,
            acceptedTerms: this.enabled,
          };

          await axios.post(`${url}/teachers/updatePassword`, data, {
            headers: {
              authorization: token,
            },
          });

          this.getTeacher();

          this.firstAccess = false;
        } catch (error) {
          console.error(error);
        }
      }
    },
    setMessage(msg) {
      this.page = msg;
    },
    toLoginPedagogico() {
      this.$router.go("/login/pedagogico");
    },
    logout() {
      this.logoutDialog = false;
      this.toLoginPedagogico();
      localStorage.removeItem("accessToken");
      localStorage.removeItem("id");
      localStorage.removeItem("licensed");
      localStorage.removeItem("courseId");
      localStorage.removeItem("classId");
      localStorage.removeItem("classname");
    },
  },
};
</script>

<style>
.logoutStyle {
  bottom: 0vh;
  width: 100%;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
}

.v-navigation-drawer__content {
  width: 103%;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f128;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(136, 136, 136);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(121, 121, 121);
}
</style>
