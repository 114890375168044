<template>
  <div>
    <v-toolbar dense class="elevation-1">
      <v-icon color="yellow" class="text-h4">mdi-account-school</v-icon>
      <v-toolbar-title
        style="letter-spacing: 0.5rem"
        class="ml-3 font-weight-bold"
      >
        {{ $t('student.student_title') }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn color="darkpink" rounded dark @click="returnMenu()">
        <v-icon left> mdi-arrow-left </v-icon>
        {{ $t('botoes.botao_voltar') }}
      </v-btn>
      <v-dialog
        v-model="notas"
        persistent
        :width="
          $vuetify.breakpoint.mdAndUp
            ? '30%'
            : $vuetify.breakpoint.xsOnly
            ? '90%'
            : '70%'
        "
      >
        <v-card class="rounded-lg">
          <v-card-title
            class="
              white--text
              font-weight-bold
              px-4
              d-flex
              justify-space-between
              align-center
              darkblue
            "
          >
            <span>{{ $t('student.stundent_aula.aula_title') }}</span>
            <v-icon dark @click="closeNotasContainer()">mdi-close</v-icon>
          </v-card-title>

          <v-card-text class="py-4 px-6">
            <span
              class="mb-8 font-weight-medium black--text"
              style="font-size: 1rem"
            >
              {{ $t('student.stundent_aula.aula_inseri') }}
            </span>
            <div style="width: 80px">
              <v-text-field
                v-model="grade"
                type="number"
                min="0"
                max="10"
                color="blue"
                label="Nota"
                @change="verifygrade(grade)"
              />
            </div>
          </v-card-text>

          <v-card-actions class="d-flex justify-end align-center">
            <v-btn
              color="darkpink"
              class="px-6"
              rounded
              text
              @click="saveNote()"
            >
              {{ $t('botoes.botao_alterar') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model="dialog"
        persistent
        :width="$vuetify.breakpoint.mdAndUp ? '65%' : '90%'"
      >
        <v-card class="rounded-lg">
          <v-card-title
            class="
              darkblue
              white--text
              px-4
              d-flex
              justify-space-between
              align-center
            "
          >
            <span>{{ $t('student.student_headers.headers5') }}</span>

            <v-btn dark icon @click="closeRegister()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text class="px-2 px-sm-4 px-md-6 px-lg-10 py-4">
            <!-- <v-form ref="form">
                <v-row>
                  <v-col cols="12" sm="6" md="7">
                    <v-text-field
                      disabled
                      color="blue"
                      v-model="formStudent.name"
                      :rules="[rules.required]"
                      label="Nome*"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="5">
                    <v-text-field
                      disabled
                      color="blue"
                      v-model="formStudent.user"
                      :rules="[rules.required, rules.user, rules.exist]"
                      @change="verifyUser(formClient.username)"
                      label="Usuário*"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      disabled
                      color="blue"
                      label="Email*"
                      :rules="[rules.required, rules.email]"
                      v-model="formStudent.email"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" v-if="!editMode">
                    <v-text-field
                      disabled
                      color="blue"
                      v-model="formStudent.password"
                      :rules="[rules.required, rules.length(4)]"
                      label="Senha*"
                      :type="showPassword ? 'text' : 'password'"
                      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="showPassword = !showPassword"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-select
                      disabled
                      item-color="darkblue"
                      color="blue"
                      v-model="formStudent.grade"
                      :items="grades"
                      :rules="[rules.option]"
                      label="Série*"
                      required
                    ></v-select>
                  </v-col>
                  <v-col class="d-flex" cols="12" sm="6" md="8">
                    <v-select
                      disabled
                      item-color="darkblue"
                      color="blue"
                      v-model="formStudent.schoolId"
                      :items="schools"
                      item-text="name"
                      item-value="id"
                      label="Escola*"
                      :rules="[rules.option]"
                    ></v-select>
                  </v-col>
                </v-row>
              </v-form> -->
            <v-data-table
              :headers="headersClass"
              :items="classes"
              :footer-props="{
                'items-per-page-options': [20, 45, 60, 100, -1],
                'items-per-page-all-text': 'Todos',
                'items-per-page-text': 'Itens por página',
                'show-first-last-page': true,
              }"
            >
              <template v-slot:[`item.applied`]="{ item }">
                <v-btn
                  rounded
                  outlined
                  small
                  @click="onNotasList(item)"
                  color="blue"
                  class="px-2 mx-1"
                >
                  <v-icon small class="mr-1">mdi-pencil</v-icon>
                  {{ $t('student.stundent_aula.aula_nota') }}
                </v-btn>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-toolbar>
    <v-dialog v-model="dialogDelete" max-width="550px">
      <v-card
        class="rounded-lg py-3 error--text"
        style="border-left: 12px inset red"
      >
        <v-card-actions class="d-flex justify-end pr-4"> </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card class="elevation-0 mt-1">
      <v-text-field
        v-if="!pequisa"
        color="blue"
        v-model="search"
        append-icon="mdi-magnify"
        :label="$t('label.label_pesquisa')"
        single-line
        hide-details
      ></v-text-field>
      <v-card-title>
        <v-spacer></v-spacer>
        <v-autocomplete
          item-color="darkblue"
          color="blue"
          v-model="search"
          :items="teams"
          item-text="name"
          :label="$t('student.student_headers.headers3')"
          required
          :style="
            $vuetify.breakpoint.smAndUp ? 'max-width: 30%' : 'max-width: 60%'
          "
        ></v-autocomplete>
      </v-card-title>
      <v-spacer></v-spacer>
      <v-data-table
        :headers="headers"
        :items="Students"
        :search="search"
        :footer-props="{
          'items-per-page-options': [20, 45, 60, 100, -1],
          'items-per-page-all-text': $t('events.events_headers.headers1'),
          'items-per-page-text': $t('student.student_paginas'),
          'show-first-last-page': true,
        }"
      >
        <template v-slot:[`item.actions`]="{ item }" v-if="!licensed">
          <v-btn
            small
            outlined
            rounded
            color="darkblue"
            class="px-2 mx-1"
            @click="editItem(item)"
          >
            <v-icon small class="mr-1"> mdi-star </v-icon>
            {{ $t('student.stundent_aula.aula_nota') }}
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
    <v-snackbar
      v-model="successSnackbar"
      timeout="2500"
      elevation="3"
      class="mx-auto"
      bottom
      color="success"
      outlined
      text
    >
      <v-icon large color="success" class="mr-2">mdi-check-circle </v-icon>
      <span class="subtitle-1 black--text">
        {{ $t('alerta.alerta_sucesso') }}
      </span>
      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="successSnackbar = false"
          rounded
          color="success"
          class="font-weight-black"
        >
        {{ $t('botoes.botao_ok') }}
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar
      v-model="errorSnackbar"
      timeout="2500"
      elevation="3"
      class="mx-auto"
      bottom
      color="error"
      outlined
      text
    >
      <v-icon large color="error" class="mr-2">mdi-close-circle </v-icon>
      <span class="subtitle-1 black--text">
        {{ $t('alerta.alerta_erro') }}
      </span>
      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="errorSnackbar = null"
          rounded
          color="error"
          class="font-weight-black"
        >
          {{ $t('botoes.botao_ok') }}
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<style scoped>
.v-dialog > .v-card > .v-card__title {
  font-size: 1.25rem;
  font-weight: 500;
  letter-spacing: 0.0125em;
  padding: 12px 12px 12px;
}
</style>

<script>
const url = process.env.VUE_APP_API_URL;
const axios = require("axios");
const token = "Baerer " + localStorage.getItem("accessToken");
export default {
  data() {
    return {
      dialog: null,
      formStudent: {},
      notas: null,
      search: "",
      headers: [
        { text: this.$t('student.student_headers.headers1'), value: "name" },
        { text: this.$t('student.student_headers.headers2'), value: "user" },
        { text: this.$t('student.student_headers.headers3'), value: "team.name" },
        { text: "", value: "actions", sortable: false, align: "end" },
      ],
      headersClass: [
        { text: this.$t('student.student_headers.headers4'), value: "Class.title" },
        { text: this.$t('student.student_headers.headers5'), value: "grade", sortable: false },
        { text: "", value: "applied", sortable: false, align: "end" },
      ],
      Students: [],
      classes: [],
      editedIndex: -1,
      dialogDelete: null,
      editMode: false,
      userExisting: false,
      rules: {
        email: (v) =>
          !!(v || "").match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ) || this.$t('regras.regra_email'),
        length: (len) => (v) =>
          (v || "").length >= len ||
          `${this.$t('regras.regra_senhaMini.senha1')} ${len} ${this.$t('regras.regra_senhaMini.senha2')}`,
        maxLength: (len) => (v) =>
          (v || "").length <= len ||
          `${this.$t('regras.regra_senhaMax.senha1')} ${len} ${this.$t('regras.regra_senhaMax.senha2')}`,
        required: (v) => !!v || this.$t('regras.regra_obrigatorio'),
        option: (v) => !!v || this.$t('regras.regra_opcao'),
        image: (v) => !!v || this.$t('regras.regra_image'),
        question: (v) => !!v || this.$t('regras.regra_question'),
        date: (v) => !!v || this.$t('regras.regra_data'),
        user: (v) =>
          !!(v || "").match(/^[a-zA-Z0-9.]*$/) ||
          `${this.$t('regras.regra_user')}`,
        exist: () => !this.userExisting || this.$t('regras.regra_existeuser'),
        none: true,
      },
      successSnackbar: false,
      errorSnackbar: false,
      nameSchool: null,
      schoolName: [],
      showPassword: false,
      teste: null,
      schools: [],
      teams: [],
      info: [],
      classId: [],
      Upgrade: [],
      grade: [],
      nota: [],
      grades: [
        this.$t('student.student_grade.grade1'),
        this.$t('student.student_grade.grade2'),
        this.$t('student.student_grade.grade3'),
        this.$t('student.student_grade.grade4'),
        this.$t('student.student_grade.grade5'),
        this.$t('student.student_grade.grade6'),
        this.$t('student.student_grade.grade7'),
        this.$t('student.student_grade.grade8'),
        this.$t('student.student_grade.grade9'),
      ],
      haveNote: null,
      licensed: localStorage.getItem("licensed"),
      pequisa: true,
      editedItem:{},
    };
  },
  methods: {
    returnMenu() {
      this.$parent.$parent.$parent.page = this.$t('admin.admin_menu.menu13');
    },
    getGrade(studentId) {
      axios
        .get(`${url}/gradesByStudent?student=${studentId}`, {
          headers: {
            authorization: token,
          },
        })
        .then((res) => {
          //console.log(res);
          this.nota = res.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getGradeByStudent() {
      axios
        .get(`${url}/gradesByStudent/?student=${this.formStudent.id}`, {
          headers: {
            authorization: token,
          },
        })
        .then((res) => {
          //console.log(res);
          this.teste = res.data;
          //console.log("clases",this.classes)
          for (let i = 0; i < this.classes.length; i++) {
            for (let n = 0; n < this.teste.length; n++) {
              if (this.classes[i].Class.id == this.teste[n].classId) {
                this.classes[i].grade = this.teste[n].grade;
              }
              if (this.classes[i].Class.id == this.teste[n].classId) {
                this.classes[i].grade = this.teste[n].grade;
              }
            }
          }
          // console.log(this.formStudent)
          this.$forceUpdate();
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getStudent() {
      axios
        .get(`${url}/studentsForTeacher`, {
          headers: {
            authorization: token,
          },
        })
        .then((res) => {
          //console.log(res);
          this.Students = res.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    PostGrade(grade) {
      axios
        .post(`${url}/grade/gradeForTeacher`, grade, {
          headers: {
            authorization: token,
          },
        })
        .then((res) => {
          //console.log(res);
          this.Upgrade = res.data;
          this.editItem(this.editedItem);
          this.notas = false;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getTeam() {
      axios
        .get(`${url}/teamsForTeacher`, {
          headers: {
            authorization: token,
          },
        })
        .then((res) => {
          this.teams = res.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getClasses(team) {
      axios
        .get(`${url}/courseAssembly/classesByCourses/${team}`, {
          headers: {
            authorization: token,
          },
        })
        .then((res) => {
          this.classes = res.data;

          this.$forceUpdate();
        })
        .catch((err) => {
          console.error(err);
        });
    },

    getSchools() {
      axios
        .get(`${url}/schools`, {
          headers: {
            authorization: token,
          },
        })
        .then((res) => {
          this.schools = res.data;
        });
    },

    async saveNote() {
      if (this.haveNote) {
        this.updateGrade();
      }
      if (!this.haveNote) {
        this.registerGrade();
      }
      console.log(this.editedIndex);
      this.editItem(this.editedItem);
    },

    updateGrade() {
      axios
        .put(
          `${url}/grade/updateByTeacher/${this.formStudent.id}/${this.classId}`,
          { grade: this.grade },
          {
            headers: {
              authorization: token,
            },
          }
        )
        .then(() => {
          this.editItem(this.editedItem);
          this.notas = false;
        })
        .catch((error) => {
          console.error(error);
          alert("Error");
        });
    },

    closeRegister() {
      this.dialog = false;
      this.editMode = false;
    },

    onNotasList(item) {
      //console.log(item);
      this.grade = 0;
      let arrayClass = [];
      this.nota.forEach((element) => {
        arrayClass.push(element.classId);
      });
      if (arrayClass.indexOf(item.fk_classId) != -1) {
        this.haveNote = true;
      } else {
        this.haveNote = false;
      }
      this.classId = item.fk_classId;
      this.editedIndex = item.id;
      this.notas = true;
    },

    registerGrade() {
      const body = {
        classId: this.classId,
        studentId: this.formStudent.id,
        teamId: this.formStudent.teamId,
        grade: this.grade,
      };
      this.PostGrade(body);
    },

    verifygrade(num) {
      if (num < 0) {
        this.grade = 0;
      } else if (num > 10) {
        this.grade = 10;
      } else {
        this.grade = num;
      }
    },

    closeNotasContainer() {
      this.notas = false;
    },

    editItem(item) {
      this.editedItem = item;
      this.editedIndex = item.id;
      this.formStudent = Object.assign({}, item);
      this.getClasses(item.courseId);
      this.getGrade(item.id);
      this.getGradeByStudent();

      this.dialog = true;
      this.editMode = true;
    },
  },
  created() {
    this.getStudent();
    this.getSchools();
    this.getTeam();
  },
};
</script>
