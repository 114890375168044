<template>
  <div>
    <v-row style="height: 85vh" class="ma-4">
      <v-col cols="12" md="6" v-for="(role, index) in roles" :key="index">
        <v-card
          :color="role.color"
          class="
            py-4
            px-4 px-sm-6 px-md-8
            d-flex
            flex-column
            align-center
            justify-center
          "
          style="height: 100%"
        >
          <v-row>
            <v-col v-if="$vuetify.breakpoint.smAndUp" cols="6">
              <v-img :src="role.image" />
            </v-col>
            <v-col
              class="
                white--text
                rounded-lg
                d-flex
                flex-column
                align-center
                justify-center
                font-weight-bold
              "
              style="font-size: 1.9rem"
            >
              {{ roleteste[index].cargo }}
              <div
                style="font-size: 0.95rem"
                class="mt-2 font-weight-regular text-justify"
              >
                {{ roleteste[index].descricao }}
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          fixed
          right
          fab
          absolute
          large
          color="darkcolor"
          class="elevation-8"
          style="bottom: 30px; left: 30px"
          v-bind="attrs"
          v-on="on"
          @click="openDialog()"
        >
          <v-icon color="white">mdi-help</v-icon>
        </v-btn>
      </template>
      <span> {{ $t('botoes.botao_ajuda') }} </span>
    </v-tooltip>
    <v-tooltip top>
      <!-- <template v-slot:activator="{ on, attrs }">
        <v-btn
          fixed
          left
          fab
          absolute
          large
          color="darkcolor"
          class="elevation-8"
          style="bottom: 30px; right: 30px"
          v-bind="attrs"
          v-on="on"
          @click="returnMenu()"
        >
          <v-icon color="white">mdi-reply</v-icon>
        </v-btn>
      </template> -->
      <span> {{ $t('botoes.botao_voltar') }} </span>
    </v-tooltip>
    <v-dialog
      v-model="helpDialog"
      :max-width="$vuetify.breakpoint.mdAndUp ? '40%' : '80%'"
    >
      <v-card class="rounded-lg pa-6">
        <v-carousel
          continuous
          cycle
          :interval="9000"
          show-arrows-on-hover
          hide-delimiters
        >
          <v-carousel-item
            v-for="(help, i) in helpContent"
            :key="i"
            class="mx-auto"
          >
            <div
              class="d-flex flex-column align-center justify-center"
              style="width: 100%; height: 100%"
            >
              <v-avatar size="180">
                <v-img :src="help.img" class="mx-auto" />
              </v-avatar>

              <span
                class="text-center mt-6 mx-2 mx-sm-10 font-weight-medium"
                :style="$vuetify.breakpoint.mdAndUp ? 'font-size: 1.2rem' : ''"
                v-html="help.text"
              />
              <div
                class="mt-6 font-weight-bold"
                :style="
                  $vuetify.breakpoint.mdAndUp
                    ? 'font-size: 0.8rem'
                    : 'font-size: 0.6rem'
                "
              >
                {{ i + 1 }} / 3
              </div>
            </div>
          </v-carousel-item>
        </v-carousel>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
const axios = require("axios");
const url = process.env.VUE_APP_API_URL;
const token = "Baerer " + localStorage.getItem("accessToken");
const authorization = { headers: { authorization: token } };

export default {
  data() {
    return {
      step: null,
      helpContent: [
        {
          id: 1,
          img: "https://makereducacional.com.br/astronaut/helpRole/HelpRoleStar.svg",
          text: `<b class='blue--text' style='font-size: 1.4rem'> ${this.$t('roles.Role_help.help1')}`,
        },
        {
          id: 2,
          img: "https://makereducacional.com.br/astronaut/helpRole/HelpRoleFloating.svg",
          text: `<b class='blue--text' style='font-size: 1.4rem'>${this.$t('roles.Role_help.help2')}`,
        },
        {
          id: 3,
          img: "https://makereducacional.com.br/astronaut/helpRole/HelpRoleMoon .svg",
          text: `<b class='blue--text' style='font-size: 1.4rem'>${this.$t('roles.Role_help.help3')}`,
        },
      ],
      roleteste: [
        {
          cargo: this.$t('roles.Role_cargo.cargo1'),
          descricao: this.$t('roles.Role_descricao.descricao1'),
        },
        {
          cargo: this.$t('roles.Role_cargo.cargo2'),
          descricao: this.$t('roles.Role_descricao.descricao2')
        },
        {
          cargo: this.$t('roles.Role_cargo.cargo1'),
          descricao: this.$t('roles.Role_descricao.descricao3')
        },
        {
          cargo: this.$t('roles.Role_cargo.cargo3'),
          descricao: this.$t('roles.Role_descricao.descricao4')
        },
      ],
      helpDialog: false,
      roles: [],
    };
  },
  created() {
    this.getRoles();
  },
  methods: {
    async getRoles() {
      let rol = await axios.get(`${url}/roles`, authorization);
      this.roles = rol.data;
    },
    openDialog() {
      this.helpDialog = true;
      this.step = 1;
    },
    returnMenu() {
      this.$parent.$parent.$parent.page = this.$t('admin.admin_menu.menu13');
    },
  },
};
</script>

<style></style>
