<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-top-transition"
    width="90%"
    :fullscreen="$vuetify.breakpoint.xsOnly"
  >
    <v-card>
      <v-card-title class="font-weight-medium text-subtitle-1 darkblue white--text">
        Criar Novo Tópico
        <v-spacer />
        <v-btn dark icon @click="closeDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="px-4 px-lg-12 py-4 py-sm-8 py-md-10">
        <v-text-field
          v-model="newTopic.topico"
          label="Título do Tópico"
          outlined
          required
        ></v-text-field>

        <vue-editor
          v-model="newTopic.pergunta"
          :editorOptions="editorOptions"
          class="mb-4"
          placeholder="Digite os detalhes do tópico..."
        ></vue-editor>

        <v-btn color="primary" class="mt-4" @click="submitTopic">Criar Tópico</v-btn>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { VueEditor } from 'vue2-editor';
const axios = require("axios");
const url = process.env.VUE_APP_API_URL;
const token = "Baerer " + localStorage.getItem("accessToken");
const authorization = { headers: { authorization: token } };

export default {
  components: {
    VueEditor,
  },
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
    teamId: {
      type: Number,
      required: true,
    },
    courseId: {
      teamId: Number,
      required: true,
    },
    user: {
        type: Object, 
        required: true,
    }
  },
  data() {
    return {
      newTopic: {
        topico: '',
        pergunta: '',
      },
      editorOptions: {
        placeholder: 'Digite os detalhes do tópico...',
      },
    };
  },
  methods: {
    closeDialog() {
      this.$emit('close');
    },
    async submitTopic() {
      if (this.newTopic.topico.trim() !== '' && this.newTopic.pergunta.trim() !== '') {

        const newTopic = {
          schoollId: this.user.schoolId, 
          courseId: this.courseId, 
          teamId: this.teamId, 
          idusertype: this.user.role, 
          userId: this.user.id, 
          topico: this.newTopic.topico,
          pergunta: this.newTopic.pergunta, 
          views: 0, 
          closed: false, 
          visible: true,
        };
        try {
            const res = await axios.post(`${url}/forum/topics`, newTopic, authorization);
            this.$emit('create-topic', res.success);
            this.newTopic.topico = '';
            this.newTopic.pergunta = '';
            this.closeDialog();
        } catch (error) {
            console.error('Erro ao enviar resposta:', error);
        }
      }
    },
  },
};
</script>

<style scoped>
/* Adicione seus estilos aqui */
</style>