<template>
  <v-card class="rounded-lg">
    <v-card-title class="font-weight-medium text-subtitle-1 darkblue white--text">
      Visualizar Tópico
      <v-spacer />
      <v-btn dark icon @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text class="px-4 px-lg-12 py-4 py-sm-8 py-md-10">
      <!-- Informações Adicionais do Tópico -->
      <div class="d-flex flex-row justify-content-between mb-4 info-topico">
        <div>Data de Criação: {{ formatDate(localTopic.createdAt) }}</div>
        <div>Última Resposta: {{ formatDate(localTopic.last_reply_at) }}</div>
        <div>Visualizações: {{ localTopic.views }}</div>
        <div>Respostas: {{ localTopic.replies.length }}</div>
      </div>

      <!-- Informações do Tópico -->
      <div class="d-flex flex-row mb-4">
        <div class="text-center mr-4">
          <div><b>{{ localTopic.role }}</b></div>
          <v-avatar size="64" class="mb-2">
            <v-img :src="localTopic.avatar" />
          </v-avatar>
          <div>{{ localTopic.created_by }}</div>
        </div>
        <div class="flex-grow-1">
          <div class="rounded-lg p-4" :style="{ backgroundColor: '#f5f5f5', padding: '16px' }">
            <div class="font-weight-bold text-h6">{{ localTopic.topico }}</div>
            <div class="mt-2" v-html="localTopic.pergunta"></div>
          </div>
        </div>
      </div>

      <!-- Respostas -->
      <div v-for="reply in paginatedReplies" :key="reply.id" class="d-flex flex-row mb-4">
        <div class="text-center mr-4">
          <div><b>{{ reply.role }}</b></div>
          <v-avatar size="48" class="mb-2">
            <v-img :src="reply.avatar" />
          </v-avatar>
          <div>{{ reply.created_by }}</div>
        </div>
        <div class="flex-grow-1">
          <div class="rounded-lg p-4" :style="{ backgroundColor: '#e0e0e0', padding: '16px' }">
            <div v-html="reply.resposta"></div>
            <div class="mt-2 text-caption text-right">{{ formatDate(reply.createdAt) }}</div>
          </div>
        </div>
      </div>

      <!-- Paginação -->
      <v-pagination
        v-if="totalPages > 1"
        v-model="currentPage"
        :length="totalPages"
        class="my-4"
        @input="handlePageChange"
      ></v-pagination>

      <v-divider class="my-4"></v-divider>

      <!-- Nova Resposta -->
      <vue-editor
        v-model="newReply"
        class="mb-4"
        :editorOptions="editorOptions"
      ></vue-editor>
      <v-btn color="primary" class="mt-4" @click="submitReply">Enviar Resposta</v-btn>
    </v-card-text>
  </v-card>
</template>

<script>
import { VueEditor } from 'vue2-editor';
import axios from 'axios';

const url = process.env.VUE_APP_API_URL;
const token = "Bearer " + localStorage.getItem("accessToken");
const authorization = { headers: { authorization: token } };

export default {
  components: {
    VueEditor,
  },
  props: {
    topic: {
      type: Object,
      required: true,
    },
    user: {
      type: Object, 
      required: true, 
    },
    dialog: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      localTopic: { ...this.topic }, // Cria uma cópia local da prop 'topic'
      newReply: '',
      currentPage: 1,
      repliesPerPage: 5,
      editorOptions: {
        placeholder: 'Digite sua resposta...',
      },
    };
  },
  mounted() {
    this.listReplies();
  },
  computed: {
    totalPages() {
      return Math.ceil(this.localTopic.replies.length / this.repliesPerPage);
    },
    paginatedReplies() {
      const start = (this.currentPage - 1) * this.repliesPerPage;
      const end = start + this.repliesPerPage;
      return this.localTopic.replies.slice(start, end);
    },
  },
  methods: {
    async listReplies() {
      try {
        const response = await axios.get(`${url}/forum/replies/${this.localTopic.id}`, authorization);
        // Atualiza a propriedade 'replies' de forma reativa
        this.$set(this.localTopic, 'replies', response.data);
      } catch (error) {
        console.error('Erro ao listar respostas:', error);
      }
    },
    handlePageChange(page) {
      this.currentPage = page;
    },
    async submitReply() {
      if (this.newReply.trim() !== '') {
        const resposta = {
          forumTopicsId: this.localTopic.id,
          teamId: this.localTopic.teamId,
          idusertype: this.user.role, 
          userId: this.user.id,
          resposta: this.newReply,
          visible: true,
        };
        try {
          await axios.post(`${url}/forum/replies`, resposta, authorization);
          this.newReply = ''; // Limpar o campo de resposta após o envio
          await this.listReplies(); // Atualizar a lista de respostas
        } catch (error) {
          console.error('Erro ao enviar resposta:', error);
        }
      }
    },
    formatDate(dateString) {
      const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };
      return new Date(dateString).toLocaleDateString('pt-BR', options);
    },
  },
  watch: {
    topic: {
      handler(newTopic) {
        this.localTopic = { ...newTopic }; // Atualiza a cópia local quando a prop mudar
      },
      deep: true,
    },
    dialog(newVal) {
      if (newVal) {
        this.listReplies(); // Chama listReplies quando o diálogo é aberto
      }
    },
  },
};
</script>

<style scoped>
.info-topico > div {
  margin-right: 20px; /* Ajuste conforme necessário */
}
/* Remove margem do último item */
.info-topico > div:last-child {
  margin-right: 0;
}
</style>