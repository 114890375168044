<template>
  <div class="backcolor" style="height: 100%">
    <div class="red-box">
      <div class="prev" @click="returnMenu()">
        <v-icon style="color:rgb(0 159 255); font-size: 350%">mdi-chevron-double-left</v-icon>
        <div style="font-size: 125%;">{{classes.Course.name }}</div>
      </div>
      <!-- <div class="prev">
        <div style="font-size: 125%;">teste de texto</div>
        <v-icon style="color:rgb(0 159 255); font-size: 350%"> mdi-chevron-double-right </v-icon>
      </div> -->
    </div>
    <v-row class="pa-3">
      <v-col cols="12" md="12" lg="8">
        <v-tabs-items :value="classTab">
          <v-card-text class="my-4">
            <v-row no-gutters>
              <v-col>
                <v-row align="center" no-gutters>
                  <v-col
                    cols="12"
                    class="grow font-weight-bold black--text"
                  >
                    <div class="container">
                      <div>
                        <div class="text">
                          {{ classes.Class.title }} - Projeto: {{classes.Class.project }}
                        </div>
                        <div> 
                          {{ classes.Course.name }} 
                        </div>
                        <div style="margin: 10px 0 20px;"> 
                          {{ classes.Class.description }} 
                        </div>
                        <div class="information">
                          <v-icon style="margin: 0 5px 0 0; color:rgb(0 159 255)">mdi-timer</v-icon>
                          <div style="margin: 0 10px 0 0;">{{lessonplan.time}}</div>
                          <v-icon style="margin: 0 10px 0 0; color:rgb(0 159 255)">mdi-book-open-variant</v-icon>
                          <div>{{lessonplan.nivel}}</div>
                        </div>
                      </div>
                      <img class="blue-box" :src="classes.Class.tumbnail">
                    </div>
                    <hr />
                    <div v-if="lessonplan.lesssonPlans">
                      <div class="text" >
                        Plano de Aula
                      </div>
                      <div class="text-content" > 
                        <p v-html="formatText(lessonplan.lesssonPlans)"></p>
                      </div>
                    </div>
                    <hr />
                    <div v-if="lessonplan.simplifyClass">
                      <div class="text" >
                        Simplifique sua Aula
                      </div>
                      <div class="text-content">
                        <p v-html="formatText(lessonplan.simplifyClass)"></p>
                      </div>
                    </div>
                    <hr />
                    <div v-if="lessonplan.amplificationClass">
                      <div class="text" >
                        Amplifique sua Aula
                      </div>
                      <div class="text-content">
                        <p v-html="formatText(lessonplan.amplificationClass)"></p>
                      </div>
                    </div>
                    <hr />
                    <div v-if="lessonplan.urlvideo">
                      <div class="text" >
                        Vídeo - Plano de Aula
                      </div>
                      <div v-if="video" class="d-flex justify-center align-center">
                        <vimeo-player
                          ref="player"
                          :video-url="`https://player.vimeo.com/video/${lessonplan.urlvideo}`"
                        />
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-tabs-items>
      </v-col>
      <v-col v-if="quickacessverification" ls="12" md="12" lg="4" >
        <v-card-text class="my-4">
          <v-alert
            class="mx-2 pointer elevation-4 d-flex align-center"
            :color="colorLookLight(0)"
            text
            outlined
            :prominent="!$vuetify.breakpoint.xsOnly"
            min-height="100%"
          >
            <v-expansion-panels focusable>
              <v-expansion-panel style="background-color: rgb(175 233 255); font-size: 85%;" v-for="kt in classheaders" :key="kt.id">
                <v-expansion-panel-header ripple @click="getClassByKits(kt.id)">
                  {{ kt.text }}
                  <template v-slot:actions>
                    <v-icon color="darkblue"> $expand </v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row align="center" no-gutters>
                    <v-col
                    v-for="data in cousertext"
                    :key="data.id"
                    cols="12"
                    class="grow font-weight-bold black--text"
                    >
                      <div class="text-content" > 
                        <br>
                        <p v-html="formatText(data.conteudo)"></p>
                      </div>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-alert>
        </v-card-text>
      </v-col>
      <!-- <v-col v-if="quickacessverification" ls="12" md="12" lg="4" >
        <v-card-text class="my-4">
          <v-alert
            class="mx-2 pointer elevation-4 d-flex align-center"
            :color="colorLookLight(0)"
            text
            outlined
            :prominent="!$vuetify.breakpoint.xsOnly"
            min-height="100%"
          >
            <div v-if="precisar.length" class="grow font-weight-bold black--text">
              {{ $t('suporteProfessor.suporte_tipos.suporte1') }}
            </div>
            <v-row align="center" no-gutters>
              <v-col
              v-for="data in precisar"
              :key="data.id"
              cols="12"
              class="grow font-weight-bold black--text"
              @click="link(data.url)"
              >
                <li>
                  {{ data.name }}
                </li>
              </v-col>
            </v-row>
            <br>
            <div v-if="recursos.length > 0" class="grow font-weight-bold black--text">
              {{ $t('suporteProfessor.suporte_tipos.suporte2') }}
            </div>
            <v-row align="center" no-gutters>
              <v-col
              v-for="data in recursos"
              :key="data.id"
              cols="12"
              class="grow font-weight-bold black--text"
              @click="link(data.url)"
              >
                <li>
                  {{ data.name }}
                </li>
              </v-col>
            </v-row>
            <br>
            <div v-if="habilidade.length > 0" class="grow font-weight-bold black--text">
              {{ $t('suporteProfessor.suporte_tipos.suporte3') }}
            </div>
            <v-row align="center" no-gutters>
              <v-col
              v-for="data in habilidade"
              :key="data.id"
              cols="12"
              class="grow font-weight-bold black--text"
              @click="link(data.url)"
              >
                <li>
                  {{ data.name }}
                </li>
              </v-col>
            </v-row>
          </v-alert>
        </v-card-text>
      </v-col> -->
    </v-row>
  </div>
</template>

<script>
const axios = require("axios");
const url = process.env.VUE_APP_API_URL;
const token = "Baerer " + localStorage.getItem("accessToken");

export default {
  data() {
    return {
      dialogRegister: false,
      rules: {
        email: (v) => !!(v || "").match(/@/) || this.$t('regras.regra_email'),
        length: (len) => (v) =>
          (v || "").length >= len ||
          `${this.$t('regras.regra_senhaMini.senha1')} ${len} ${this.$t('regras.regra_senhaMini.senha2')}`,
        required: (v) => !!v || this.$t('regras.regra_obrigatorio'),
        option: (v) => !!v || this.$t('regras.regra_opcao'),
        image: (v) => !!v || this.$t('regras.regra_image'),
        question: (v) => !!v || this.$t('regras.regra_question'),
        date: (v) => !!v || this.$t('regras.regra_data'),
        none: true,
      },
      videos: 0,
      d_carousel: false,
      m_corousel: 0,
      mounting: [],
      classes: [],
      items: [],
      description: null,
      noDataClass: this.$t('homework.homework_kit'),
      quickacessverification: false,
      summary: null,
      classId: null,
      courses: [],
      classColors: [
        { light: "pink ", dark: "darkpink" },
        { light: "cyan", dark: "darkcyan" },
        { light: "yellow", dark: "darkyellow" },
      ],
      headers: [
        { text: this.$t('homework.homework_title'), value: "Class.title" },
        { text: "", value: "details", sortable: false, align: "end" },
      ],
      classheaders: [
        { text: this.$t('suporteProfessor.suporte_tipos.suporte1'), id: "P" },
        { text: this.$t('suporteProfessor.suporte_tipos.suporte2'), id: "A" },
        { text: this.$t('suporteProfessor.suporte_tipos.suporte3'), id: "H"},
      ],
      dialogSummarie: false,
      dialogExercises: false,
      summ: null,
      isSelecting: false,
      selectedFile: null,
      exerc: null,
      allExerc: null,
      mountFile: [],
      presentationFile: null,
      presentation: [],
      noSummaries: false,
      noExercises: false,
      classDetailsDialog: false,
      tab: null,
      classTab: null,
      cousertext: [],
      hasPresentation: false,
      hasMounting: false,
      hasExercise: false,
      detailsData: { Kit: {} },
      videoID: null,
      video: false,
      files: {},
      recursos: [],
      precisar: [],
      habilidade: [],
      lessonplan: [],
    };
  },

  async created() {
    await this.getClassByCourses(localStorage.getItem("courseId"))
    await this.lesssonPlans(localStorage.getItem("classId"))
    await this.teacherhelp(localStorage.getItem("classId"))
  },
  methods: {
    getClassByKits(id){
      if(id === "P") {
        this.cousertext = this.precisar
      }
      else if(id === "A") {
        this.cousertext = this.recursos
      }
      else {
        this.cousertext = this.habilidade
      }
    },
    formatText(text) {
      return text.replace(/'(.*?)'/g, '<strong>$1</strong>');
    },
    returnMenu() {
      this.$router.push("/teacherFuture/ClassInfo")
      this.$parent.$parent.$parent.page = 'ClassInfo';
    },
    handleFileImport() {
      this.isSelecting = true;
      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        },
        { once: true }
      );
      this.$refs.uploader.click();
    },
    colorLookLight(index) {
      let position = (index + 1) % 3;
      let color = this.classColors[position].light;
      return color;
    },
    colorLookDark(index) {
      let position = (index + 1) % 3;
      let color = this.classColors[position].dark;
      return color;
    },

    async teacherhelp(id) {
      let precisarindex = 0
      let recursosindex = 0
      let habilidadeindex = 0
      let m = await axios.get(`${url}/teacherSupport/${id}`, {
        headers: {
          authorization: token,
        },
      });
      if(m.data.length > 0){
        this.quickacessverification = true
        for (let index = 0; index < m.data.length; index++) {
          const quickacess =  m.data[index];
          if(quickacess.type == "P"){
            this.precisar[precisarindex] = quickacess
            precisarindex += 1
          }
          else if(quickacess.type == "A"){
            this.recursos[recursosindex] = quickacess
            recursosindex += 1
          }
          else if(quickacess.type == "H"){
            this.habilidade[habilidadeindex] = quickacess
            habilidadeindex += 1
          }
        }
      }else{
        this.quickacessverification = false
      }
    },

    async lesssonPlans(id) {
      let lesson = await axios.get(`${url}/lessonPlans/${id}`, {
        headers: {
          authorization: token,
        },
      });
      this.lessonplan = lesson.data
      this.video = true
      //console.log(this.lessonplan)
    },

    getClassByCourses(courseId) {
      axios
        .get(`${url}/courseAssembly/classesByCourses/${courseId}`, {
          headers: {
            authorization: token,
          },
        })
        .then((res) => {
          let classId = localStorage.getItem("classId")
          this.getCourseFiles(courseId);
          this.classTab = 1;
          this.videos = 0;
          for (let index = 0; index < res.data.length; index++) {
            if(res.data[index].fk_classId == classId){
              this.classes = res.data[index];
              //console.log(this.classes)
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    async getCourseFiles(id) {
      try {
        let res = await axios.get(`${url}/filesCourses/byCourse/${id}`, {
          headers: {
            authorization: token,
          },
        });
        this.files = res.data;
      } catch (error) {
        console.error(error);
      }
    },

    async getMounting(id) {
      let m = await axios.get(`${url}/lessonsMountings/byClass/${id}`, {
        headers: {
          authorization: token,
        },
      });
      if (m.data.length !== 0) {
        this.m_corousel = 0;
        this.mountFile = m.data;
        this.hasMounting = true;
      }

      let present = await axios.get(
        `${url}/lessonsPresentations/byClass/${id}`
      );

      if (present.data.length !== 0) {
        this.presentation = present.data;
        console.log("Videos", this.presentation);
        this.hasPresentation = true;
      } else {
        this.hasPresentation = false;
      }
    },

    async seeExercises(id) {
      let res = await axios.get(`${url}/exercisesByClass?lessons=${id}`, {
        headers: {
          authorization: token,
        },
      });

      if (res.data.length != 0) {
        this.hasExercise = true;
        this.exerc = res.data;
      } else {
        this.hasExercise = false;
        this.exerc = [];
      }
    },
    toEdit(res) {
      let id = res[0].classId;
      this.$parent.$parent.$parent.exerciseId = id;
      this.$emit("message", "Exercícios");
      this.$parent.$parent.$parent.page = "Exercícios";
    },
    cleanDatas() {
      this.videos = 0;
      this.items = [];
      this.hasExercise = false;
      this.hasMounting = false;
      this.hasPresentation = false;
      this.detailsData = { Kit: {} };
    },

    async link(data) {
      window.open(data)
    },

    verifyTab() {
      this.items.push({
        id: 1,
        title: this.$t('homework.homework_tab.tab3'),
      });

      if (this.hasPresentation) {
        this.items.push({
          id: 2,
          title: this.$t('homework.homework_tab.tab4'),
        });
      }
      if (this.hasMounting) {
        this.items.push({
          id: 3,
          title: this.$t('homework.homework_tab.tab5'),
        });
      }
      if (this.hasExercise) {
        this.items.push({
          id: 4,
          title:  this.$t('homework.homework_tab.tab6'),
        });
      }
    },
  },
};
</script>

<style scoped>
.correctImg {
  border: 5px solid #468c00;
}

.pointer {
  cursor: pointer;
}

.text-content {
  white-space: pre-line; /* Para preservar as quebras de linha */
}
.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: inherit;
  margin: -20px 0px 0px 0px;
}

.information {
  display: flex;
  justify-content: flex-start;
  padding: inherit;
  align-items: center;
}

.blue-box {
  width: 150px;
  height: 150px;
  background-color: rgb(0 159 255);
  margin: 0 0 10px;
}

.red-box {
  display: flex;
  justify-content: space-between;
  padding: inherit;
  align-items: center;
}

.prev {
  margin: 10px 10px 0;
  align-items: center;
  display: flex;
  color:rgb(0 159 255);
  cursor: pointer;
}

.text {
  font-size: 1.5rem; 
  color: rgb(0 159 255);
  margin: 20px 0 15px;
}
</style>
