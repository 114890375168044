<template>
  <div class="backcolor">
    <v-toolbar dense class="elevation-1">
      <v-icon color="blue" class="text-h4">mdi-certificate</v-icon>
      <v-toolbar-title
        :style="
          $vuetify.breakpoint.smAndUp
            ? 'letter-spacing: 0.5rem'
            : 'letter-spacing: 0.2rem'
        "
        class="ml-3 font-weight-bold"
        >{{ $t('certificado.cert_title') }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-dialog v-model="dialogCert" max-width="950px">
      <v-card :img="require('../../assets/certificado.png')">
        <v-card-actions style="position: absolute; right: 93%; top: -9px;">
          <v-btn
            rounded
            outlined
            @click="dialogCert = false"
            color="transparent"
          >
            <v-icon class="mr-1" color="error">mdi-close-circle</v-icon>
          </v-btn>
        </v-card-actions>
        <div id="formTeacher" ref="dialogContent" >
          <div>
            <br><br><br>
            <h1 style="text-align: center; font-family: emoji; font-size: 80px;">{{ $t('certificado.cert_conteudo.conteudo1') }}</h1>
            <br><br>
            <p style="text-align:center; font-size: 25px;">
            {{ $t('certificado.cert_conteudo.conteudo2') }} {{formTeacher.teacherName}} {{ $t('certificado.cert_conteudo.conteudo3') }}
            </p>
            <p style="text-align:center; font-size: 25px; font-weight: bold">
            {{formTeacher.courseName}}
            </p>
            <p style="text-align: center; font-size: 25px;">
            {{ $t('certificado.cert_conteudo.conteudo4') }} {{formTeacher.workload}} {{$t('certificado.cert_conteudo.couteudo5')}} {{formTeacher.dateBegin}} {{ $t('certificado.cert_conteudo.conteudo6') }} <br>
            {{ $t('certificado.cert_conteudo.conteudo7') }} {{formTeacher.dateEnd}}
            </p>
            <br><br><br>
            <p style="text-align: center; font-size: 28px;">
             Maker Education
            </p>
            <p style="text-align: center; font-size: 28px; margin-top: -4%;">
             _________________
            </p>
            <br><br><br>
            <!-- <h1 style="text-align: center">{{ formTeacher.courseName }}</h1>
            <p>Por {{ formTeacher.teacherName }}</p>
            <div>Data de inicio: {{formTeacher.dateBegin}}</div>
            <div>Data de Conconclusão {{formTeacher.dateEnd}}</div>
            <p>Assinado por: Maker Education</p> -->
          </div>
        </div> 
        <v-card-actions style="position: absolute; top: 92%;">
          <v-btn color="primary" @click="exportPDF">{{ $t('botoes.botao_PDF') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row class="ma-4">
      <v-col
        cols="12"
        md="6"
        lg="4"
        v-for="certificado in Cert"
        :key="certificado.id"
      >
        <v-card class="rounded-lg py-4 px-2" style="height: 100%">
          <div
            class="
              d-flex
              flex-column
              align-center
              justify-space-around
              text-center
            "
            style="height: 100%; min-height: 25vh"
          >
            <div class="font-weight-bold" style="font-size: 1.2rem">
              {{ $t('certificado.cert_simples.conteudo1') }}
            </div>
            <div class="mb-4 font-weight-medium" style="font-size: 0.9rem">
              {{ $t('certificado.cert_simples.conteudo2') }} {{certificado.courseName}}
            </div>
            <div class="mb-4 font-weight-medium" style="font-size: 0.9rem">
              {{ $t('certificado.cert_simples.conteudo3') }} {{ certificado.teacherName }}
            </div>

            <div style="font-size: 0.9rem">
              <div>{{ $t('certificado.cert_simples.conteudo4') }} {{ certificado.dateBegin.split('-').reverse().join('/')  }}</div>
              <div>{{ $t('certificado.cert_simples.conteudo5') }} {{ certificado.dateEnd.split('-').reverse().join('/')  }}</div>
              <div>{{ $t('certificado.cert_simples.conteudo6') }} {{ certificado.workload }} {{ $t('certificado.cert_simples.conteudo7') }}</div>
            </div>
            <div class="mt-6">
              <v-btn
                rounded
                outlined
                small
                icon
                @click="editItem(certificado)"
                color="darkblue"
                class="px-2 mx-1"
              >
                <v-icon small>mdi-eye</v-icon>
              </v-btn>
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>
    </div>
  </template>

<style scoped></style>

<script>
const url = process.env.VUE_APP_API_URL;
const axios = require("axios");
const token = "Baerer " + localStorage.getItem("accessToken");
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
export default {
  data() {
    return {
      dialog: null,
      formTeacher: {},
      search: "",
      headers: [
        { text: this.$t('certificado.cert_headers.headers1'), value: "name" },
        { text: this.$t('certificado.cert_headers.headers2'), value: "user" },
        { text: "", value: "actions", sortable: false, align: "end" },
      ],
      teachers: [],
      editedIndex: -1,
      menuIniDate: false,
      menuFinDate: false,
      activePicker: null,
      dialogCert: null,
      editMode: false,
      Cert: '',
      userExisting: false,
      rules: {
        numbersOnly: value => /^[0-9]+$/.test(value) || this.$t('regras.regra_numero'),
        email: (v) =>
          !!(v || "").match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ) || this.$t('regras.regra_email'),
        length: (len) => (v) =>
          (v || "").length >= len ||
          `${this.$t('regras.regra_senhaMini.senha1')} ${len} ${this.$t('regras.regra_senhaMini.senha2')}`,
        maxLength: (len) => (v) =>
          (v || "").length <= len ||
          `${this.$t('regras.regra_senhaMax.senha1')} ${len} ${this.$t('regras.regra_senhaMax.senha1')}`,
        required: (v) => !!v || this.$t('regras.regra_obrigatorio'),
        option: (v) => !!v || this.$t('regras.regra_opcao'),
        image: (v) => !!v || this.$t('regras.regra_image'),
        question: (v) => !!v || this.$t('regras.regra_question'),
        date: (v) => !!v || this.$t('regras.regra_data'),
        user: (v) =>
          !!(v || "").match(/^[a-zA-Z0-9.]*$/) ||
          `${this.$t('regras.regra_user')}`,
        exist: () => !this.userExisting || this.$t('regras.regra_existeuser'),
        none: true,
      },
      schools: [],
      selectedSchool: [],
      varias: null,
      searchDialog: true,
      teachersNot: [],
      searchTeachers: "",
      // IDdaEscola: 15,
      showPassword: false,

      appliedClassesDialog: false,
      selectedTeacherClasses: [],
      classColors: [
        { light: "pink ", dark: "darkpink" },
        { light: "cyan", dark: "darkcyan" },
        { light: "yellow", dark: "darkyellow" },
      ],
    };
  },
  methods: {
    exportPDF() {
      html2canvas(this.$refs.dialogContent).then(canvas => {
        const dialogContent = canvas.toDataURL('image/jpeg');
        const pdf = new jsPDF();
        const imageUrl = require('../../assets/certificado.png'); 
        const imageWidth = 250;
        const imageHeight = 170;
        const centerX = pdf.internal.pageSize.width / 2;
        const centerY = pdf.internal.pageSize.height / 3;
        const xPosition = centerX - (imageHeight / 2);
        const yPosition = centerY - (imageWidth);
        pdf.addImage(imageUrl, 'PNG', 0, 0, pdf.internal.pageSize.width, pdf.internal.pageSize.height); 
        pdf.addImage(dialogContent, 'JPEG',  xPosition, yPosition, imageWidth, imageHeight,  '', 'FAST', -90);
        pdf.save('certificade ' + '' + this.formTeacher.courseName + '.pdf');
      });
    },
    async verifyUser(res) {
      let response = await axios.get(`${url}/clients/user?user=${res}`, {
        headers: {
          authorization: token,
        },
      });
      if (response.data != null) {
        this.userExisting = true;
      } else {
        this.userExisting = false;
      }
    },
    openSearchTeachers() {
      this.getTeachersNotLinked();
      this.searchDialog = true;
    },
    inscriptionTeacher(item) {
      const data = { teacherId: item.id };
      axios
        .post(`${url}/teacherInscriptions`, data, {
          headers: {
            authorization: token,
          },
        })
        .then(() => {
          this.getTeachersNotLinked();
          this.getTeacherBySchool();
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getTeachersNotLinked() {
      axios
        .get(`${url}/teachersNot`)
        .then((res) => {
          this.teachersNot = res.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getTeacherBySchool() {
      axios
        .get(`${url}/teachersForSchool`, {
          headers: {
            authorization: token,
          },
        })
        .then((res) => {
          this.teachers = res.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getTeacherCert() {
      axios
        .get(`${url}/teachersCertForID`, {
          headers: {
            authorization: token,
          },
        })
        .then((res) => {
          this.Cert = res.data
        })
        .catch((error) => {
          console.error(error);
        });
    },

    closeRegister() {
      this.dialog = false;
      this.dialogDelete = false
      this.formTeacher = {};
      this.editMode = false;
      this.$refs.form.resetValidation();
    },
    editItem(item) {
      this.editedIndex = item.id;
      this.formTeacher = Object.assign({}, item);
      this.formTeacher.dateBegin = this.formTeacher.dateBegin.split('-').reverse().join('/')
      this.formTeacher.dateEnd = this.formTeacher.dateEnd.split('-').reverse().join('/')
      this.dialogCert = true
    },
    viewAppliedClasses(teacher) {
      axios
        .get(`${url}/appliedClassesByTeacher/${teacher.id}`, {
          headers: {
            authorization: token,
          },
        })
        .then((res) => {
          this.selectedTeacherClasses = res.data;
        })
        .catch((error) => {
          console.error(error);
        });
      this.appliedClassesDialog = true;
    },
    colorLookLight(index) {
      let position = (index + 1) % 3;
      let color = this.classColors[position].light;
      return color;
    },
    colorLookDark(index) {
      let position = (index + 1) % 3;
      let color = this.classColors[position].dark;
      return color;
    },
  },
  created() {
    this.getTeacherBySchool();
    this.getTeacherCert();
  },
};
</script>
