<template>
  <v-container>
    <v-row>
      <v-col cols="12" class="d-flex justify-space-between align-center">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Pesquisar tópicos"
          single-line
          hide-details
        ></v-text-field>
        <v-btn color="primary" @click="openCreateTopicDialog">Criar Novo Tópico</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="filteredTopics"
          item-key="id"
          class="elevation-1"
          @click:row="viewTopic"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Tópicos do Fórum</v-toolbar-title>
            </v-toolbar>
          </template>
          <template v-slot:item.createdAt="{ item }">
            {{ formatDate(item.createdAt) }}
          </template>
          <template v-slot:item.last_reply_at="{ item }">
            {{ formatDate(item.last_reply_at) }}
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-dialog
      v-model="topicDialog"
      persistent
      transition="dialog-top-transition"
      width="90%"
      :fullscreen="$vuetify.breakpoint.xsOnly"
    >
      <forum-topic-details 
        :topic="selectedTopic" 
        :user="user"
        :dialog="topicDialog"
        @close="topicDialog = false" />
    </v-dialog>

    <create-topic-dialog
      :dialog="createTopicDialog"
      :teamId="teamId"
      :courseId="courseId"
      :user="user"
      @close="createTopicDialog = false"
      @create-topic="addTopic"
    />
  </v-container>
</template>

<script>
import ForumTopicDetails from './ForumTopicDetails.vue';
import CreateTopicDialog from './CreateTopicDialog.vue';

const axios = require("axios");
const url = process.env.VUE_APP_API_URL;
const token = "Baerer " + localStorage.getItem("accessToken");
const authorization = { headers: { authorization: token } };

export default {
  components: {
    ForumTopicDetails,
    CreateTopicDialog,
  },
  props: {
    teamId: {
      type: Number,
      required: true,
    },
    courseId: {
      type: Number, 
      required: true, 
    },
    dialog: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      user: {},
      topicDialog: false,
      createTopicDialog: false,
      search: '',
      selectedTopic: {},
      headers: [
        { text: 'Tópico', value: 'topico' },
        { text: 'Criado por', value: 'created_by' },
        { text: 'Turma', value: 'team' },
        { text: 'Criado em', value: 'createdAt' },
        { text: 'Última Resposta', value: 'last_reply_at' },
        { text: 'Respostas', value: 'reply_count' },
        { text: 'Visualizações', value: 'views' },
      ],
      topics: [],
    };
  },
  computed: {
    filteredTopics() {
        return this.topics.filter(topic =>
            topic.topico.toLowerCase().includes(this.search.toLowerCase())
        );
    },
  },
  async mounted() {
    await this.decodeToken();
    await this.listTopics();
  }, 
  methods: {
    async listTopics() {
      let response = await axios.get(`${url}/forum/topics/${this.user.schoolId}/${this.courseId}`, {
        headers: {
          authorization: token,
        },
      });
      this.topics = response.data;
    },
    formatDate(date) {
      const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };
      return new Date(date).toLocaleDateString(undefined, options);
    },
    openCreateTopicDialog() {
      this.createTopicDialog = true;
    },
    addTopic(newTopic) {
        console.log(newTopic);
        this.listTopics();
    },
    async viewTopic(item) {
      await axios.put(`${url}/forum/topics/updateViews/${item.id}`, [], authorization);
      item.replies = [];
      item.views++;
      this.selectedTopic = item;
      this.topicDialog = true;
    },
    loadScript(src) {
      return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = src;
        script.onload = () => resolve();
        script.onerror = () => reject(new Error(`Erro ao carregar o script: ${src}`));
        document.head.appendChild(script);
      });
    },
    async decodeToken() {
      try {
        await this.loadScript('https://cdn.jsdelivr.net/npm/jwt-decode@3.1.2/build/jwt-decode.min.js');
        const token = localStorage.getItem("accessToken");
        if (token) {
          try {
            const decodedToken = window.jwt_decode(token);
            this.user = decodedToken;
          } catch (error) {
            console.error("Erro ao decodificar o token:", error);
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
  watch: {
    dialog(newVal) {
      if (newVal) {
        this.listTopics();
      }
    },
  },
};
</script>

<style scoped>
/* Adicione seus estilos aqui */
</style>